import React, { Component } from "react";
import Authentication from "../../APIs/Authentication";
import GetShipments from "../../APIs/GetShipments";
import GetRateQuoteRequests from "../../APIs/GetRateQuoteRequests";
import Navbar from "../../Components/Navbar/Navbar";
import SearchResults from "../../Views/SearchResults";
import PrimaryBtn from "../../SmallComponents/PrimaryBtn";
import * as moment from "moment";
import DashboardWelcome from "../../Components/WelcomeMessages/DashboardWelcome";
import MultiCarrierWelcomeMessage from "../../Components/WelcomeMessages/MultiCarrierWelcomeMessage";
import TableEditView from "../../Views/TableEditView";
import "./Dashboard.css";
import GenericCollection from "../../Collections/GenericCollection";
import Alert01 from "../../SmallComponents/Alert01";
// import Collections from "../../collections.json";
import { UserProvider } from "../../UserDataContext";
import ShipmentsDataNormalization from "../../Helpers/ShipmentsDataNormalization";
import UpdatedShipmentsCheck from "../../Components/UpdatedShipmentsCheck/UpdatedShipmentsCheck";
import SearchShipments from "../../APIs/SearchShipments";
import history from "../../APIs/History";
import HalPOCSupport from "./DashboardComponents/HalPOCSupport";
import VersionCheck from "../../Components/VersionCheck/VersionCheck";

class Dashboard extends Component {
  constructor() {
    super();
    this.setSearchTerm = this.setSearchTerm.bind(this);
    this.setSearchValu = this.setSearchVal.bind(this);
    this.condRenderLoadingIcon = this.condRenderLoadingIcon.bind(this);
    this.condRenderSearchResults = this.condRenderSearchResults.bind(this);
    this.clearSearchResults = this.clearSearchResults.bind(this);
    this.condRenderSearchingIcon = this.condRenderSearchingIcon.bind(this);
    this.refreshShippments = this.refreshShippments.bind(this);
    this.tableEditModeOnOff = this.tableEditModeOnOff.bind(this);
    this.handleChangeTableItem = this.handleChangeTableItem.bind(this);
    this.showHideTableEditMode = this.showHideTableEditMode.bind(this);
    this.condRenderTableEditMode = this.condRenderTableEditMode.bind(this);
    this.condRenderEditMultipleStatus = this.condRenderEditMultipleStatus.bind(
      this
    );
    this.renderCollections = this.renderCollections.bind(this);
    this.searchMachine = this.searchMachine.bind(this);
    this.getRateQuotes = this.getRateQuotes.bind(this);
    this.getShipments = this.getShipments.bind(this);
    this.updateSingleShipment = this.updateSingleShipment.bind(this);
    this.checkForNewDataFromAPI = this.checkForNewDataFromAPI.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.loadContextUserData = this.loadContextUserData.bind(this);
    this.setSearchResultsFields = this.setSearchResultsFields.bind(this);
    this.clearShipData = this.clearShipData.bind(this);
    this.changeCarrier = this.changeCarrier.bind(this);
    this.resetOnCarrierClientChange = this.resetOnCarrierClientChange.bind(this);
    this.multiCarrierWelcomeShowHideAction = this.multiCarrierWelcomeShowHideAction.bind(this);
    this.setActiveCollections = this.setActiveCollections.bind(this);

    this.state = {
      searching: false,
      searchTerm: "",
      searchVal: "",
      showSearchResults: false,
      multiCarrierWelcomeShowHide: "hide",
      refresButtonDisabled: "yes",
      tableEditMode: false,
      tableEditSelected: [],
      tableEditData: [],
      showTableEditModeModal: false,
      showTableEdit: false,
      countTableEditNum: 0,
      carrierClientId: "Default",
      carrierClientIdNav: "Default",
      showErrorMessage: false,
      errorMessageContent: null,
      searchShowFields: [
        "allUpdateFieldsHilightHide",
        "invoicePro",
        "statusBtns",
        // "appointmentPickupDate",
        // "appointmentPickupTimeIn",
        // "appointmentPickupTimeOut",
        // "appointmentDeliveryDate",
        // "appointmentDeliveryTimeIn",
        // "appointmentDeliveryTimeOut",
        "statusBtnShowHidePuOut",
        "statusBtnShowHideIT",
        "statusBtnShowHideITDelayed",
        "statusBtnShowHideOFD",
        "statusBtnShowHideDEL",
        "actualPickupDate",
        "actualPickupTimeIn",
        "actualPickupTimeOut",
        "actualDeliveryDate",
        "actualDeliveryTimeIn",
        "actualDeliveryTimeOut",
        "billedCost",
        "chargesMessage",
        "signedBy",
        "messages",
        "documents",
        "updateShipmentDataBtn",
        "settlementIDHilightHide",
        "settlementDateHilightHide",
      ],
      searchHilightFields: [],
      userData: {},
      userData2: [],
      lastUpdate: null,
      currentCustomer: "",
      activeCollections: [],
      dashboardWelcome: "",
    };
  }

  shipmentAutoRefreshID = 1;



  loadContextUserData() {
    if (JSON.parse(localStorage.getItem("userData2"))) {
      this.setState({ userData2: JSON.parse(localStorage.getItem("userData2")) }, () => {
        let selected = localStorage.getItem("carrierId")
        let selectedClientData = this.state.userData2.filter((clientData) => clientData.carrierId === selected)
        selectedClientData[0].config = JSON.parse(selectedClientData[0].carrierpAccountConfig)
        this.setState({ userData: selectedClientData[0] })
      });
    }

  }



  changeCarrier(carrierId) {
    // console.log('carrier changed', clientCode)
    let selectedClientData = this.state.userData2.filter((clientData) => clientData.carrierId === carrierId)
    // console.log('selectedClientData', selectedClientData[0].carrierId)
    this.resetOnCarrierClientChange()
    localStorage.setItem("carrierId", selectedClientData[0].carrierId);
    localStorage.setItem("id", selectedClientData[0].id);
    localStorage.setItem("name", selectedClientData[0].name);
    localStorage.setItem("carrierName", selectedClientData[0].carrierName);
    localStorage.setItem("phone", selectedClientData[0].phone);
    localStorage.setItem("emailAddress", selectedClientData[0].email);
    localStorage.setItem("token", selectedClientData[0].id);
    localStorage.setItem("clientName", selectedClientData[0].clientName);
    localStorage.setItem("carrierClientId", selectedClientData[0].clientCode);
    localStorage.setItem("clientLogo", selectedClientData[0].clientLogo);
    // When changing carriers we set local storage and reload the page.
    document.location.reload()
  }

  resetOnCarrierClientChange() {
    // console.log('resetOnCarrierClientChange')
    let rQRequests = {}
    if (this.state.rateQuoteRequests) {
      rQRequests = this.state.rateQuoteRequests
    }
    rQRequests.data = []
    this.setState({
      shipments: [],
      rateQuoteRequests: rQRequests,
      shipData: false
    })
  }



  componentDidMount() {
    // userData is the data returned from the authentication API
    // const userData = this.context;
    // Check if the user is logged in on page load and gather shipments
    Authentication.isLoggedIn();
    this.loadContextUserData();
    this.getShipments();
    let refresh5minutes = 300000
    const tewelveMinutes = 720000
    this.shipmentAutoRefreshID = setInterval(this.refreshShippments, tewelveMinutes);
    this.getRateQuotes();

    // perform version check every 1 minute
    VersionCheck()
    setInterval(async () => { await VersionCheck() }, 60000)

    // Checking for the carrierClientID to use for what collections / filters to load

    this.setState(
      {
        carrierClientId: localStorage.getItem("carrierClientId"),
        currentCarrierId: localStorage.getItem("carrierId")
      },
      () => {
        this.setActiveCollections(this.state.currentCarrierId);
      }
    );
  }


  setActiveCollections(carrierId) {
    let selected = JSON.parse(localStorage.getItem("userData2")).filter((item) => item.carrierId === carrierId)
    let activeCollections = JSON.parse(selected[0].carrierpAccountConfig)

    // this.setState({ activeCollections: activeCollections }, () => this.setSearchResultsFields())

    // is activeCollections an array?
    if (Array.isArray(activeCollections)) {
      this.setState({ activeCollections: activeCollections }, () => this.setSearchResultsFields())
    }
    // is activeCollections an object?
    if (typeof activeCollections === 'object' && activeCollections !== null && activeCollections?.collections) {
      this.setState({ activeCollections: activeCollections.collections }, () => this.setSearchResultsFields())
      if (activeCollections.dashboardWelcome) {
        this.setState({ dashboardWelcome: activeCollections.dashboardWelcome })
      }
    }
  }

  // Use the fields that a user has selected to show in all shipments as the fields used when they search a shipment
  setSearchResultsFields() {
    let allShipmentsFields = this.state.activeCollections.filter(
      (item) =>
        item.company === this.state.carrierClientId &&
        (item.id === "all" ||
          item.id === "All" ||
          item.id.includes("all") ||
          item.id.includes("All"))
    );
    if (allShipmentsFields[0]) {
      this.setState({
        searchShowFields: allShipmentsFields[0].showFields,
      });
    }
  }

  // For updating single shipments after update submit
  // This is old and uses shipment v2 and thus is not in use
  updateSingleShipment(bol) {
    // console.log("debug Single Shipment update sent.", bol);
    GetShipments.getSingleShipmentByBOL(bol)
      .then((result) => {
        // console.log("debug Single shipment update recieved", result.data);
        let returnedShipmentInArray = [result.data];
        let returnedShipmentNormalized = ShipmentsDataNormalization(
          returnedShipmentInArray
        );
        // Check existing shipments if they need to be updated
        let existingShipments = this.state.shipments;
        let foundIndex = existingShipments.findIndex(
          (x) => x.id === result.data.id
        );
        // console.log(
        //   "debug Single Shipment returnedShipmentNormalized",
        //   returnedShipmentNormalized[0]
        // );
        existingShipments[foundIndex] = returnedShipmentNormalized[0];
        if (foundIndex === -1) {
        } else {
          this.setState({
            shipments: existingShipments,
          });
        }
        // Also check search results in case that needs upating
        if (this.state.searchResults.length) {
          // console.log("Update SearchResults");
          let existingSearchResults = this.state.searchResults;
          let foundIndexInSearch = existingSearchResults.findIndex(
            (x) => x.id === result.data.id
          );
          existingSearchResults[foundIndexInSearch] =
            returnedShipmentNormalized[0];
          if (foundIndexInSearch === -1) {
          } else {
            this.setState({
              searchResults: existingSearchResults,
            });
          }
        }
      })
      .catch(function (err) {
        console.warn(
          "Dashboard.js, GetShipments, updateSingleShipment, error",
          err
        );
      });
  }

  // Shipments
  getShipments() {
    GetShipments.getShipmentsByCarrier()
      .then((result) => {
        // console.log("getshipments result", result);
        // searchEnable is passed as props to the nav to disable search options until search is loaded, any value will enable the search buttons and options, '' will disable them
        // checkForNewDataFromAPI checks to see if the new data is in fact different than the existing shipment data, to avoid rerendering a lot of components for carriers with lots of shipments we only set the new result.data to the shipment data (shipData) if in fact it is new data

        if (this.checkForNewDataFromAPI(result.data) === true) {
          // console.log('Dashboard.js, GetShipments SETTING STUFF?', this.state.lastUpdate)
          // console.log('here 2')
          this.setState({
            shipData: { data: result.data },
            shipments: ShipmentsDataNormalization(result.data),
            searchEnable: "yes",
            refreshLoading: false,
            showErrorMessage: false,
          });
          this.setState({ lastUpdate: new Date() });
          // refresButtonDisabled after 10 seconds
          setTimeout(() => {
            this.setState({ refresButtonDisabled: "" });
          }, 10000);
        } else {
          // console.log('here 3')
          this.setState({
            searchEnable: "yes",
            refreshLoading: false,

          });
          // refresButtonDisabled after 10 seconds
          setTimeout(() => {
            this.setState({ refresButtonDisabled: "" });
          }, 10000);
        }
      })
      .catch((error) => {
        // console.log("Dashboard.js, GetShipments, error", error);
        let ThreeHoursAgo = new Date(new Date().setHours(new Date().getHours() - 3))

        if (this.state.lastUpdate < ThreeHoursAgo) {

          this.setState({
            shipData: [],
            shipments: [],
            rateQuoteRequests: []
          })
        }
        if (error && error.response) {
          if (error.response.status === 500) {
            // console.log("oh noes");
            this.setState({
              // showErrorMessage: true,
              errorMessageContent: toString().error,
              searchEnable: "yes",
              refreshLoading: false,
              refresButtonDisabled: "",
            });
            // console.log('here')
          } else {
            console.warn("No status seen on getshipments error?");
          }
        }
        this.setState({ refreshLoading: false })
      });

    this.setState({
      multiCarrierWelcomeShowHide: localStorage.getItem(
        "multiCarrierWelcomeShowHide")
    });
  }

  errorMessage(error) {
    if (this.state.showErrorMessage === true) {
      return (
        <Alert01 status={"error"} message={this.state.errorMessageContent} />
      );
    } else {
      return null;
    }
  }

  // Rate Requests
  getRateQuotes() {
    GetRateQuoteRequests.getRateRequestsByCarrier()
      .then((result) => {
        // console.log('RESULT', result)
        this.setState({
          rateQuoteRequests: result,
        });
      })
      .catch(function (err) {
        // console.log("Dashboard.js, getRateQuotes, error", err)
      });
  }

  // Functions for Search
  setSearchTerm(searchTerm) {
    this.setState({ searchTerm: searchTerm });
    this.setState({ searching: true });
  }

  setSearchVal(searchVal) {
    this.setState({ searchVal: searchVal });
    this.setState({ searching: true });
  }

  clearSearchResults() {
    this.setState({
      searching: false,
      searchTerm: "",
      searchVal: "",
      searchResults: [],
      showSearchResults: false,
      showTableEditModeModal: false,
    });
  }

  async searchMachine() {
    if (this.state.searching === true) {
      if (this.state.searchTerm !== 'status' && this.state.searchVal.replace(/\s+/g, "").length < 4) {
        this.setState({ searching: false, searchResults: [], showSearchResults: false })
        alert('Searches must be at least 4 characters long')
        return
      }

      switch (this.state.searchTerm) {

        case "allnums":

          var searchValNoSpace = this.state.searchVal.replace(/\s+/g, "");
          let allnumsSearchResults = await SearchShipments.Search(this.state.searchTerm, searchValNoSpace);
          // console.log('searchResult ', allnumsSearchResults)

          // The current v4 search doesn't include the ability to search the user controls of sub shipments inside a consolidation doing that here locally, it will only find shipments loaded locally so that maybe an issue if searching beyond the scope of the initally loaded shipments
          let localSearchResults = this.state.shipments.filter(shipment =>
            shipment.ucNum.includes(this.state.searchVal)
          )

          allnumsSearchResults = allnumsSearchResults.concat(localSearchResults)

          const ids = allnumsSearchResults.map(o => o.id)
          const shipmentsNoDuplicates = allnumsSearchResults.filter(({ id }, index) => !ids.includes(id, index + 1))

          this.setState({ searchResults: shipmentsNoDuplicates }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });

          break;

        case "pudate":

          let pudateSearchResult = await SearchShipments.Search(this.state.searchTerm, this.state.searchVal);
          // console.log('searchResult ', pudateSearchResult)
          this.setState({ searchResults: pudateSearchResult }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });
          break;

        case "deldate":

          let deldateSearchResult = await SearchShipments.Search(this.state.searchTerm, this.state.searchVal);
          // console.log('searchResult ', pudateSearchResult)
          this.setState({ searchResults: deldateSearchResult }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });
          break;

        case "settledate":
          // The current v4 search doesn't include the ability to search settlement dates, doing that here locally, it will only find shipments loaded locally so that maybe an issue if searching beyond the scope of the initally loaded shipments

          // console.log('settledate search')
          // console.log('settledate search', this.state.searchVal)
          let searchResultRemitDateA = this.state.shipments.filter((s) => {
            // console.log('settledate search', s.payments[0].checkDate.slice(0, 10))
            if (s.payments && s.payments.length) {
              // console.log('settledate search checking', s.payments[0].checkDate.slice(0, 10))
              // console.log('result', moment(s.payments[0].checkDate.slice(0, 10)).isSame(this.state.searchVal))
              return moment(s.payments[0].checkDate.slice(0, 10)).isSame(this.state.searchVal)
            }

          }
          );
          this.setState({ searchResults: searchResultRemitDateA }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });
          break;

        case "pucity":

          let pucitySearchResult = await SearchShipments.Search(this.state.searchTerm, this.state.searchVal);
          // console.log('searchResult ', pudateSearchResult)
          this.setState({ searchResults: pucitySearchResult }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });
          break;

        case "delcity":

          let delcitySearchResult = await SearchShipments.Search(this.state.searchTerm, this.state.searchVal);
          // console.log('searchResult ', pudateSearchResult)
          this.setState({ searchResults: delcitySearchResult }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });
          break;

        case "status":

          let statusSearchResult = await SearchShipments.Search(this.state.searchTerm, this.state.searchVal);
          // console.log('searchResult ', pudateSearchResult)
          this.setState({ searchResults: statusSearchResult }, () =>
            this.setState({
              showSearchResults: true,
            })
          );
          this.setState({ searching: false });

          break;

        default:
          return console.log("No searchTerm selected");
      }
    }
  }

  // Update for when the search paramaters is passed up to Dashboard.js from Search and any other updates
  componentDidUpdate(prevProps, prevState) {
    this.searchMachine();
  }

  componentWillUnmount() {
    // Need to clear the interval or it will continue to run even when the component is unmounted
    clearInterval(this.shipmentAutoRefreshID);
  }

  // Searching Icon
  condRenderSearchingIcon() {
    if (this.state.searching) {
      return (
        <div className="searchingParent">
          <div className="searchingIco">
            <i className="fas fa-search fa-spin fa-3x" />
          </div>
          <div className="searchingTxt">Searching...</div>
        </div>
      );
    }
  }

  // Render Search Results
  condRenderSearchResults() {
    if (this.state.showSearchResults) {
      return (
        <SearchResults
          searchResults={this.state.searchResults}
          showFields={this.state.searchShowFields}
          hilightFields={this.state.searchHilightFields}
          updateSingleShipment={this.updateSingleShipment}
          refreshShippments={this.refreshShippments}
        />
      );
    } else {
      return null;
    }
  }

  renderCollections() {
    if (this.state.activeCollections || this.state.rateQuoteRequests) {
      return (
        <div>
          {this.state.activeCollections
            .filter(
              (item) =>
                item.company === this.state.carrierClientId &&
                item.type === "rates" && this.state.rateQuoteRequests
            )
            .map((item) => {
              return (
                <GenericCollection
                  type="rates"
                  key={item.id}
                  id={item.id}
                  shipData={this.state.rateQuoteRequests}
                  mode="rateQuote"
                  description={item.description}
                  tipDescrption={item.tipDescrption}
                  tableEditMode={this.state.tableEditMode}
                  handleChangeTableItem={this.handleChangeTableItem}
                  hilightFields={item.hilightFields}
                  showFields={item.showFields}
                  documents={item.documents}
                />
              );
            })}
          {this.state.activeCollections
            .filter(
              (item) =>
                item.company === this.state.carrierClientId &&
                item.type !== "rates"
            )
            .map((item) => {
              return (
                <GenericCollection
                  key={item.id}
                  id={item.id}
                  filter={item.filter}
                  shipData={this.state.shipData}
                  shipments={this.state.shipments}
                  mode="shipment"
                  description={item.description}
                  tipDescrption={item.tipDescrption}
                  tableEditMode={this.state.tableEditMode}
                  handleChangeTableItem={this.handleChangeTableItem}
                  hilightFields={item.hilightFields}
                  showFields={item.showFields}
                  updateSingleShipment={this.updateSingleShipment}
                  refreshShippments={this.refreshShippments}
                  documents={item.documents}
                />
              );
            })}
        </div>
      );
    } else {
      return <div>There are no shipment collections!</div>;
    }
  }

  multiCarrierWelcomeShowHideAction() {
    this.setState({ multiCarrierWelcomeShowHide: "hide" });
    localStorage.setItem("multiCarrierWelcomeShowHide", "hide");
  }

  // Loading Icon
  condRenderLoadingIcon() {
    if (!this.state.shipData) {
      return (
        <div>
          <br />
          <div className="fa-3x loadingChild">
            <i className="fas fa-cog fa-spin" />
          </div>
          <br />
          <div className="loadingChild">Loading Shipments</div>
        </div>
      );
    } else {
      return null;
    }
  }

  refreshShippments(event) {
    if (
      this.state.refreshLoading
    ) {
      // Don't send a send request for a shipments / quotes if we're still waiting on the first request & the last update was within the last 1 hours
      // console.log('Dashboard.js, GetShipments DON"T GET THEM ', this.state.refreshLoading)
    } else {
      // console.log('Dashboard.js, GetShipments DO GET THEM')
      if (event) {
        // Refresh shipments based on a button push
        event.preventDefault();
        this.setState({
          refresButtonDisabled: "yes",
        });
        console.log('here1')
        this.setState({ refreshLoading: true });
        this.getShipments();
        this.getRateQuotes();
        // console.log("debug refreshShipments Auto");
      } else {
        // This triggers durring an auto refresh where there is no event from the button refresh button
        // console.log("refreshshipments else");
        this.setState({ refreshLoading: true });
        this.getShipments();
        this.getRateQuotes();
      }
    }
  }

  checkForNewDataFromAPI(apiData) {
    if (this.state.shipData) {
      // console.log("datacompare1", apiData);
      // console.log("datacompare2", this.state.shipData.data);
      if (
        JSON.stringify(apiData) === JSON.stringify(this.state.shipData.data)
      ) {
        // console.log("datacompare no change seen");
        return false;
      } else {
        // console.log("datacompare CHANGE seen");
        return true;
      }
    } else {
      // Need to return true if there is no shipData as it isn't set yet (inital app load) and any data will be new
      return true;
    }
  }

  // Used by UpdatedShipmentsCheck
  clearShipData() {
    this.setState({ shipData: [] });
  }

  // Table Edit Code
  // Toggles tableEditMode
  tableEditModeOnOff() {
    this.setState((prevState) => ({
      tableEditMode: !prevState.tableEditMode,
    }));
    this.setState({
      tableEditSelected: [],
    });
    if (this.state.showTableEdit) {
      this.setState({
        showTableEdit: false,
        countTableEditNum: 0,
      });
    }
  }

  handleChangeTableItem(shipmentId, checkedBool) {
    // console.log("handleChangeTableItem shipmentId", shipmentId);
    // console.log("handleChangeTableItem checkedBool", checkedBool);
    // Because some lists of shipments may include the same shipment twice it is possible a "Needs Documents" shipment might also be under "New Messages" so we need to check if shipmentIds are already in the list
    // Checked
    if (checkedBool) {
      if (this.state.tableEditSelected.includes(parseInt(shipmentId))) {
        return null;
        // Depending on how users use it is possible they might select something, and then check and uncheck something lower in the array, in that case this condition might fire here, maybe add two items and then dedupe?
      } else {
        this.state.tableEditSelected.push(parseInt(shipmentId));
      }
      this.setState({ countTableEditNum: this.state.tableEditSelected.length });
      // Unchecked
    } else {
      if (this.state.tableEditSelected.includes(parseInt(shipmentId))) {
        let filteredArray = this.state.tableEditSelected.filter(
          (item) => item !== parseInt(shipmentId)
        );
        this.setState({ tableEditSelected: filteredArray });
        this.setState({
          countTableEditNum: this.state.tableEditSelected.length,
        });
      } else {
        return null;
      }
    }
  }

  showHideTableEditMode() {
    let selectedShipments = this.state.shipData.data.filter((item) =>
      this.state.tableEditSelected.includes(item.id)
    );
    // console.log(selectedShipments);

    this.setState({ tableEditData: selectedShipments });
    this.setState((prevState) => ({
      showTableEdit: !prevState.showTableEdit,
      showHideTableEditMode: !prevState.showHideTableEditMode,
    }));
    // this.setState(prevState => ({
    //   showTableEditModeModal: !prevState.showTableEditModeModal
    // }))
  }

  condrenderTableEdiControls() {
    if (this.state.tableEditMode) {
      return (
        <PrimaryBtn
          btnName={`Open Selected Shipments (${this.state.countTableEditNum})`}
          showHide="show"
          onClick={this.showHideTableEditMode}
        />
      );
    }
  }

  condRenderTableEditMode() {
    if (this.state.showTableEdit) {
      return (
        <div>
          <TableEditView tableEditData={this.state.tableEditData} />
        </div>
      );
    } else {
      return null;
    }
  }

  condRenderEditMultipleStatus() {
    if (this.state.tableEditMode) {
      return <span>(On)</span>;
    } else {
      return <span>(Off)</span>;
    }
  }

  // Refresh Shipments Icon
  condRenderRefreshLoadingIcon() {
    if (this.state.refreshLoading === true) {
      return (
        <span>
          {" "}
          <span className="truckIconAlign">
            <i className="fas fa-truck fa-lg bounce truckIconAlign" />
            {/* <i className="fas fa-cog fa- fa-spin " /> */}
          </span>
        </span>
      );
    } else {
      return null;
    }
  }
  // Refresh Shipments Text
  condRenderLastUpdateText() {
    if (this.state.refreshLoading === true) {
      return null;
    } else {
      return (
        <span className="lastUpdatedDashboard">
          {" "}
          {this.state.lastUpdate ? <>Last Updated {moment(this.state.lastUpdate).format("MMM Do, hh:mm a")} </> : null}

        </span>
      );
    }
  }

  render() {
    return (
      <div className="container">
        { }
        <UserProvider value={this.state.userData}>
          <Navbar
            setSearchTerm={this.setSearchTerm.bind(this)}
            setSearchVal={this.setSearchVal.bind(this)}
            clearSearchResults={this.clearSearchResults.bind(this)}
            searchEnable={this.state.searchEnable}
            refreshLoading={this.state.refreshLoading}
            carrierClientId={this.state.carrierClientId}
            userData2={this.state.userData2}
            currentCarrierId={this.state.currentCarrierId}
            changeCarrier={this.changeCarrier.bind(this)}
          />
        </UserProvider>
        <div className="dashCondRenderDiv">

          <DashboardWelcome dashboardWelcome={this.state.dashboardWelcome} />

          <div className={this.state.multiCarrierWelcomeShowHide}>
            <MultiCarrierWelcomeMessage
              multiCarrierWelcomeShowHideAction={this.multiCarrierWelcomeShowHideAction}
            />
          </div>

          <div className="editMultipleControlDiv d-flex">
            <div className="flex-grow-1">
              <span
                className="editMultipelShipLink "
                onClick={this.tableEditModeOnOff}
              >
                Edit Multiple Shipments
              </span>{" "}
              {this.condRenderEditMultipleStatus()}{" "}
              {this.condrenderTableEdiControls()}
            </div>
            <div>
              {this.condRenderLastUpdateText()}
              {this.condRenderRefreshLoadingIcon()}{" "}
              <PrimaryBtn
                btnName="Refresh Shipments"
                showHide="show"
                disabled={this.state.refresButtonDisabled}
                onClick={this.refreshShippments}
              />
              <span className="loadingIconColor"> </span>
            </div>
          </div>
          <div>
            <UpdatedShipmentsCheck
              lastUpdate={this.state.lastUpdate}
              refreshShippments={this.refreshShippments}
              clearShipData={this.clearShipData}
            />
          </div>
          {this.state.userData.clientCode === "foremost" ? (<div className="d-flex justify-content-end">
            <a href="#" onClick={() => { history.push('/History') }}>View Past Bids</a>
          </div>) : null}
          {/* 
          {this.state.userData.clientCode === "exportpackers" ? (<div className="d-flex justify-content-end">
            <a href="" onClick={() => { history.push('/Consolidation') }}>Consolidate Shipments</a>
          </div>) : null} */}

          {this.state.userData.clientCode === "acmebumn" ? (<div className="d-flex justify-content-end">
            {/* download csv files in the rates directory */}
            <>
              <a href="https://www2.shippersedge.com/public/ratesd/export_2023-02-22-2.csv" download='rates'>Download Rates</a>&nbsp; - &nbsp;
              <label htmlFor="myfile" style={{ cursor: 'pointer', color: 'blue' }}>Upload Rates</label>
              <input type="file" id="myfile" name="myfile"></input>
            </>




          </div>) : null}

          <div>{this.condRenderLoadingIcon()}</div>
          <div>{this.errorMessage()}</div>
          <div>{this.condRenderTableEditMode()}</div>
          <div> {this.condRenderSearchingIcon()}</div>
          <UserProvider value={this.state.userData}>
            <div>{this.condRenderSearchResults()}</div>
          </UserProvider>
          <div>
            <UserProvider value={this.state.userData}>
              {this.renderCollections()}
            </UserProvider>
          </div>
          <hr />
          <HalPOCSupport clientId={this.state.carrierClientId} />
        </div>
      </div>
    );
  }
}

export default Dashboard;
