import React from 'react'

const TrailerNumber = ({ shipmentObjUpdateEvent, value, userData, hilightHide }) => {
    let placeholder = "For ELD Tracking"
    let className = "col-2"
    className += " " + hilightHide

    if ((userData.clientCode === "Schuff" || userData.clientCode === "graywolf" || userData.clientCode === "kwiktrip") && userData.hasOwnProperty("gpsTracking") && userData.gpsMethod === "Telematics") {
        return (
            <div class={className}>
                <label for="trailerNumber" class="form-label">Trailer Number:</label>
                <input type="text" name="loadDetails.trailerNumber" id="trailerNumber" class="form-control" placeholder={placeholder} aria-describedby="Trailer Number" value={value} onChange={(event) => shipmentObjUpdateEvent(event)}></input>
            </div>
        )
    }
    else if ((userData.clientCode.includes("halliburton"))) {
        return (
            <div class={className}>
                <label for="trailerNumber" class="form-label">Trailer Number:</label>
                <input type="text" name="loadDetails.trailerNumber" id="trailerNumber" class="form-control" aria-describedby="Trailer Number" value={value} onChange={(event) => shipmentObjUpdateEvent(event)}></input>
            </div>
        )
    }
    else {
        return null
    }
}

export default TrailerNumber
