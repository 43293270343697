// ./APIs/DeclineShipment.js

const DeclineShipment = {
  async DeclineShipment(shipmentId, secureId) {
    // console.log('DeclineShipment', shipmentId, secureId)
    var requestOptions = {
      method: 'GET'
    };

    let fetchResult = await fetch("https://app.shippersedge.com/public/carrier/declineTender.cfm?asAPI=true&i=" + shipmentId + "&secid=" + secureId, requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.warn('DeclineShipment error', error))

    return fetchResult;
  }
};
export default DeclineShipment;
