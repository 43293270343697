/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";

const DockSchedulerLink = ({ render, link, status, pickupApptDate, shipment }) => {
  console.log('DockSchedulerLink')
  const [qualified, setQualified] = useState(false)

  let carrierClientID = localStorage.getItem("carrierClientId")
  let webquotecontrol = shipment.id


  let checkDockSchedulerQualfication = () => {
    var myHeaders = new Headers();

    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
    myHeaders.append("content-type", "application/json");

    var raw = JSON.stringify({ "usercode": carrierClientID, "webquotecontrol": webquotecontrol });
    // console.log('DockSchedulerLink', raw)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.shippersedge.com/schedule/qualification/", requestOptions)
      .then(response => response.text())
      .then(result => {
        // console.log("DockSchedulerLink api call", JSON.parse(result))
        let results = JSON.parse(result)
        setQualified(results.qualified)
      })
      .catch(error => console.log('DockSchedulerLink API call error', error));
  }

  useEffect(() => {
    checkDockSchedulerQualfication()

  }, [])



  let dockLink = link

  if (qualified && dockLink !== null && dockLink !== "") {
    return (
      <>
        <Col xs="3" className={`${render}`}>
          {" "}
          <div >
            <a href={dockLink} target="_blank" rel="noopener noreferrer">
              <strong>
                Appointment Required &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                  <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                  <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              </strong>
            </a>
          </div>
        </Col>
      </>
    );
  } else {
    return null;
  }


  // if (shipment.carrierId === "kissner-testbumn" && dockLink) {
  //   // console.log('dockLink', dockLink)
  //   // console.log('dockLink', dockLink.slice(-9))
  //   dockLink = "https://scheduler.shippersedge.com/carrier/" + dockLink.slice(-9)
  // }

  // if (dockLink === null) {
  //   return <Col xs="3">{""}</Col>;
  // } else {
  //   if (
  //     (render === "show" || render === "hilight") &&
  //     dockLink !== null &&
  //     (pickupApptDate === null || pickupApptDate === "")
  //   )
  //     return (
  //       <>
  //         <Col xs="3" className={`${render}`}>
  //           {" "}
  //           <div style={{ textAlign: "center" }}>
  //             <a href={dockLink} target="_blank" rel="noopener noreferrer">
  //               Schedule Pickup Appointment
  //             </a>
  //           </div>
  //         </Col>
  //       </>
  //     );
  //   if (status === "DSP" && pickupApptDate !== null && dockLink !== null) {
  //     return (
  //       <>
  //         <Col xs="3">
  //           {" "}
  //           <div style={{ textAlign: "center", backgroundColor: "LightGrey" }}>
  //             <a href={dockLink} target="_blank" rel="noopener noreferrer">
  //               Change Pickup Appointment
  //             </a>
  //           </div>
  //         </Col>
  //       </>
  //     );
  //   } else {

  //     return <Col xs="3">{""}</Col>;
  //   }
  // }
};

export default DockSchedulerLink;
