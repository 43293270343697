import React from 'react'

const DriverInformation = ({ shipmentObjUpdateEvent, driverPhone, driverName, userData, hilightHide, DriverInformation }) => {
    const showDriverInformation = DriverInformation === "show";
    let placeholder = "For Phone Tracking"
    if (userData.hasOwnProperty("gpsTracking") && userData.gpsTracking !== null) {
        placeholder = "For Phone Tracking"
    }
    let className = "col-2 hilight"


    if (showDriverInformation) {
        return (
          <div className={className}>
            <label for="driverPhone" class="form-label">
              Driver Phone:
            </label>
            <input
              type="text"
              name="loadDetails.driverPhone"
              id="driverPhone"
              class="form-control"
              placeholder={placeholder}
              aria-describedby="Driver Phone Number"
              value={driverPhone}
              onChange={(event) => shipmentObjUpdateEvent(event)}
            ></input>
            <label for="driverName" class="form-label">
              Driver Name:
            </label>
            <input
              type="text"
              name="loadDetails.driverName"
              id="driverName"
              class="form-control"
              placeholder={placeholder}
              aria-describedby="Driver Name"
              value={driverName}
              onChange={(event) => shipmentObjUpdateEvent(event)}
            ></input>
          </div>
        );
    }
    else {

        return null
    }
}

export default DriverInformation
