import React from "react";
import "./PrimaryBtn.css";

const PrimaryBtn = props => {
  let btnDisabled = false
  if (props.disabled === 'yes') {
    btnDisabled = true
  }

  if (props.showHide === "show") {
    return (
      <span>
        {" "}
        <button
          type="button"
          className={`btn btn-sm btn-outline-primary customPrimaryBtn mr-1 ${props.extraClass
            }`}
          onClick={props.onClick}
          value={props.value}
          name={props.name}
          disabled={btnDisabled}
        >
          {props.btnName}
        </button>
      </span>
    )
  } else {
    return null;
  }
}
export default PrimaryBtn;
