import React from 'react'

const Details = ({ data: { details } }) => {
    const mode = {
        'INT_OCEAN': 'Ocean',
        'INT_GROUND': 'Ground',
    }

    if (details) {
        let modeFriendly = mode[details.mode] || details.mode
        const fontSize = Math.max(20 - modeFriendly.length, 10);
        return (
            <div>
                <table className="table table-bordered">

                    <tbody>
                        {details.mode ?
                            <tr>
                                <td>Mode</td>
                                <td style={{ fontSize: `${fontSize}px` }}>{modeFriendly}</td>
                            </tr>
                            : null
                        }
                        {details.equipment.length ?
                            <tr>
                                <td>Equip</td>
                                <td>{details.equipment}</td>
                            </tr>
                            : null}
                        {details.miles ?
                            <tr>
                                <td>Miles</td>
                                <td>{details.miles}</td>
                            </tr>
                            : null}
                        {details.totalWeight ?
                            <tr>
                                <td>Weight</td>
                                <td>{details.totalWeight} lbs</td>
                            </tr>
                            : null}
                        {details.feet ?
                            <tr>
                                <td>Weight</td>
                                <td>{details.feet}</td>
                            </tr>
                            : null}
                    </tbody>
                </table>
            </div>

        )
    } else {
        return null
    }
}

export default Details