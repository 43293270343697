

const CheckDockScheduleQualification = async (shipment) => {
    if (shipment) {
        // console.log('CheckDockScheduleQualification', shipment)

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
        myHeaders.append("Content-Type", "application/json");

        let body = {
            webquotecontrol: shipment.id,
            usercode: shipment.customerId,
            debug: false
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(body)
        };

        let qualified = false
        qualified = await fetch("https://api.shippersedge.com/schedule/qualification/", requestOptions)
            .then(response => response.json())
            .then((result) => {
                return result.qualified
            })
            .catch(error => console.warn('AcceptShipment error', error))

        // console.log('CheckDockScheduleQualification', qualified)
        return qualified
    }
    else {
        return false
    }
}

export default CheckDockScheduleQualification;