export const SubmitAccessorialRequestsByQty = async (accessorialsArray) => {
    // console.log('SubmitAccessorialRequestsByQty', accessorialsArray)
    // Retries here are for bad internet connections and timeouts when updates are sent.   
    let success = true

    const accessorialRequestsByQty = {
        "accessorialRequests": accessorialsArray
    }

    // console.log('yo i am submitting accessorial ', accessorialRequestsByQty)

    // Submit Requests

    let firstTry = await sendUpdate(accessorialRequestsByQty, 10000)
    console.log('SubmitAccessorialRequestsByQty first', firstTry)
    if (firstTry.status === 'success') {
        success = true
    } else {

        let secondTry = await sendUpdate(accessorialRequestsByQty, 20000)
        // console.log('SubmitAccessorialRequestsByQty second', secondTry)
        if (secondTry.status === 'success') {
            success = true
        } else {
            let thridTry = await sendUpdate(accessorialRequestsByQty, 45000)
            // console.log('SubmitAccessorialRequestsByQty third', thridTry)
            if (thridTry.status === 'success') {
                success = true
            } else {
                console.warn("SubmitAccessorialRequestsByQty saw an api call fail 3x")
                success = false
            }
        }
    }


    async function sendUpdate(accessorialRequestsByQty, timeOutValue) {
        console.log('SubmitAccessorialRequestsByQty sendUpdate', accessorialRequestsByQty, timeOutValue)
        return new Promise(function (resolve, reject) {
            let controller = new AbortController();
            var signal = controller.signal;

            let url =
                "https://api.shippersedge.com/carrierportal/accessorialRequests/" + accessorialRequestsByQty.accessorialRequests[0].webquotecontrol

            let myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
            myHeaders.append("content-type", "application/json");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                signal: signal,
                body: JSON.stringify(accessorialRequestsByQty)
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }
            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug SubmitAccessorialRequestsByQty.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "SubmitAccessorialRequestsByQty.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }
                    // console.log("SubmitAccessorialRequestsByQty.js", response);
                    return response.json();
                })
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    console.error("SubmitAccessorialRequestsByQty API Request failed", err);
                    resolve(err);
                });
        })
    }

    return success
};

export default SubmitAccessorialRequestsByQty