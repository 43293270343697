const UpdateShipmentStatus = {
  Update(updateData, timeOutValue) {
    // console.log("UpdateShipmentStatus formdata", updateData);
    // for (var pair of updateData.entries()) {
    //   console.log("debug FormData 2", pair[0] + ", " + pair[1]);
    // }
    return new Promise(function(resolve, reject) {
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://www2.shippersedge.com/public/carrier/updateStatusV2.cfm?wsdl";
      let request = new Request(url, {
        method: "POST",
        signal,
        body: updateData
      });
      setTimeout(() => controller.abort(), timeOutValue);
      fetch(request)
        .then(response => {
          //   console.log("debug UpdateShipmentStatus response", response);
          if (!response.ok) {
            console.error(
              "UpdateShipmentStatus.js error",
              response.statusText,
              response
            );
            throw Error(response.statusText);
          }

          //   console.log("ok");
          return response.json();
        })
        .then(response => {
          resolve(response.message);
        })
        .catch(err => {
          console.error("UpdateShipmentStatus API Request failed", err);
          resolve("Aborted");
        });
    });
  }
};

export default UpdateShipmentStatus;
