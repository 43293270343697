import React, { useState, useEffect } from 'react'
import BilledChargeEditInDetailModal from './BilledChargeEditInDetailModal'
import ForemostBilledChargeEditInDetailWarn from './ForemostBilledChargeEditInDetailWarn'
import UpdateShipmentStatus from "../../../APIs/UpdateShipmentStatus";
import UploadFile from "../../../APIs/UploadFile";
import GetShipments from '../../../APIs/GetShipments';

const BilledChargeEditInDetail = ({ shipment, accessoralRequestFeatureExists, documentUploadFile, documentUploadFileType, documentUploadBuildFormData, buildFormDataForShipmentUpdate, updateWasSubmitted }) => {
    // console.log('BilledChargeEditInDetail shipment', shipment)
    const [modalShow, setModalShow] = useState(false);
    const [foremostWarnShow, setForemostWarnShow] = useState(false);
    const [reasonsCantEditBilledCharges, setReasonsCantEditBilledCharges] = useState([]);
    const [editBilledChargesDisabled, setEditBilledChargesDisabled] = useState(false);
    const [componentState, setComponentState] = useState('start')
    const [reasonsCantEditBilledChargesShowMore, setReasonsCantEditBilledChargesShowMore] = useState(false)


    const defaultValidation = (shipment) => {
        setReasonsCantEditBilledCharges([])

        if (!shipment.dates.pickupDate || shipment.dates.pickupDate === "") {
            // push reason into reasonsCantEditBilledCharges array
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup date.'])
        }

        if (!shipment.dates.pickupTimeIn || shipment.dates.pickupTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time in.'])
        }

        if (!shipment.dates.pickupTimeOut || shipment.dates.pickupTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time out.'])
        }

        if (!shipment.dates.deliveryDate || shipment.dates.deliveryDate === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery date.'])
        }

        if (!shipment.dates.deliveryTimeIn || shipment.dates.deliveryTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time in.'])
        }

        if (!shipment.dates.deliveryTimeOut || shipment.dates.deliveryTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time out.'])
        }

        if (shipment.status.statusId !== "DEL") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Set the shipment status to delivered.'])
        }

        if (!shipment?.documents?.length) {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL and an invoice.'])
        } else {
            let signedBolExists = false
            let invoiceExists = false
            if ((shipment.documents.filter(document => document.type === 'Signed-BOLandInvoice').length)) {
                signedBolExists = true
                invoiceExists = true
            }
            if ((shipment.documents.filter(document => document.type === 'Signed-BOL').length)) {
                signedBolExists = true
            }
            if ((shipment.documents.filter(document => document.type === 'invoice').length)) {
                invoiceExists = true
            }
            if (!signedBolExists) {
                setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL.'])
                setEditBilledChargesDisabled(true)
            }
            if (!invoiceExists) {
                setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload an invoice.'])
                setEditBilledChargesDisabled(true)
            }
        }
        setComponentStateToStartAfter3Seconds()
        return true
    }

    const valudationButNoDocumentsRequirements = (shipment) => {
        setReasonsCantEditBilledCharges([])

        if (!shipment.dates.pickupDate || shipment.dates.pickupDate === "") {
            // push reason into reasonsCantEditBilledCharges array
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup date.'])
        }

        if (!shipment.dates.pickupTimeIn || shipment.dates.pickupTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time in.'])
        }

        if (!shipment.dates.pickupTimeOut || shipment.dates.pickupTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time out.'])
        }

        if (!shipment.dates.deliveryDate || shipment.dates.deliveryDate === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery date.'])
        }

        if (!shipment.dates.deliveryTimeIn || shipment.dates.deliveryTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time in.'])
        }

        if (!shipment.dates.deliveryTimeOut || shipment.dates.deliveryTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time out.'])
        }

        if (shipment.status.statusId !== "DEL") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Set the shipment status to delivered.'])
        }
        setComponentStateToStartAfter3Seconds()
        return true

    }

    const validationKwikTrip = (shipment) => {
        setReasonsCantEditBilledCharges([])
        
        if (!shipment.dates.pickupDate || shipment.dates.pickupDate === "") {
            // push reason into reasonsCantEditBilledCharges array
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup date.'])
        }

        if (!shipment.dates.deliveryDate || shipment.dates.deliveryDate === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery date.'])
        }

        if (shipment.status.statusId !== "DEL") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Set the shipment status to delivered.'])
        }
        
        // commented out per Meghan Gaul's request in ticket #'s 8482082 and 8485520 (10/18/2023)
        //if (!shipment.charges.invoiceNumber || shipment.charges.invoiceNumber === "" || shipment.charges.invoiceNumber == 0 || shipment.charges.invoiceNumber == null || shipment.charges.invoiceNumber == "Enter Pro Number") {
        //    setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the invoice number.'])
        //}

        setComponentStateToStartAfter3Seconds()
        return true

    }

    const validationHalliburton = (shipment) => {
        setReasonsCantEditBilledCharges([])
        
        if (!shipment.dates.pickupDate || shipment.dates.pickupDate === "") {
            // push reason into reasonsCantEditBilledCharges array
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup date.'])
        }

        if (!shipment.dates.pickupTimeIn || shipment.dates.pickupTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time in.'])
        }

        if (!shipment.dates.pickupTimeOut || shipment.dates.pickupTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual pickup time out.'])
        }

        if (!shipment.dates.deliveryDate || shipment.dates.deliveryDate === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery date.'])
        }

        if (!shipment.dates.deliveryTimeIn || shipment.dates.deliveryTimeIn === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time in.'])
        }

        if (!shipment.dates.deliveryTimeOut || shipment.dates.deliveryTimeOut === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the actual delivery time out.'])
        }

        if (shipment.status.statusId !== "DEL") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Set the shipment status to delivered.'])
        }

        if (!shipment?.documents?.length) {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL and an invoice.'])
        } else {
            let signedBolExists = false
            let invoiceExists = false
            if ((shipment.documents.filter(document => document.type === 'Signed-BOL').length)) {
                signedBolExists = true
            }
            if ((shipment.documents.filter(document => document.type === 'invoice').length)) {
                invoiceExists = true
            }
            if (!signedBolExists) {
                setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL.'])
                setEditBilledChargesDisabled(true)
            }
            if (!invoiceExists) {
                setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload an invoice.'])
                setEditBilledChargesDisabled(true)
            }
        }

        
        if (!shipment.charges.invoiceNumber || shipment.charges.invoiceNumber === "" || shipment.charges.invoiceNumber == 0 || shipment.charges.invoiceNumber == null || shipment.charges.invoiceNumber == "Enter Pro Number") {
           setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the invoice number.'])
        }
        /*removed on 2/8/24 per Connor; Halliburton said this is no longer needed
        // truckNumber
        if (!shipment.loadDetails.truckNumber || shipment.loadDetails.truckNumber === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the truck number.'])
        }

        // trailerNumber
        if (!shipment.loadDetails.trailerNumber || shipment.loadDetails.trailerNumber === "") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the trailer number.'])
        }
        */


        setComponentStateToStartAfter3Seconds()
        return true

    }

    const validationFishinCo = (shipment) => {
        setReasonsCantEditBilledCharges([])

        if (!shipment?.documents?.length) {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL.'])
        } else {
            let signedBolExists = false
            if ((shipment.documents.filter(document => document.type === 'Signed-BOL').length)) {
                signedBolExists = true
            }
            if (!signedBolExists) {
                setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Upload a signed BOL.'])
                setEditBilledChargesDisabled(true)
            }
        }

        if (!shipment.charges.invoiceNumber || shipment.charges.invoiceNumber === "" || shipment.charges.invoiceNumber == 0 || shipment.charges.invoiceNumber == null || shipment.charges.invoiceNumber == "Enter Pro Number") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Enter the invoice number.'])
        }

        if (shipment.status.statusId !== "DEL") {
            setReasonsCantEditBilledCharges(prevReasons => [...prevReasons, 'Set the shipment status to delivered.'])
        }

        setComponentStateToStartAfter3Seconds()
        return true

    }

    //
    // Set what validation function to use based on the customer id
    // Different customers can thus have different validation requirements / functions.
    //
    let validationFunction = defaultValidation

    if (shipment.customerId === 'rembrandtfoods' || shipment.customerId === 'dlfcanada') {
        validationFunction = valudationButNoDocumentsRequirements
    }

    if (shipment.customerId === 'kwiktrip') {
        validationFunction = validationKwikTrip
    }

    if (shipment.customerId.includes('halliburton')) {
        validationFunction = validationHalliburton
    }

    if (shipment.customerId === 'fishinco') {
        validationFunction = validationFishinCo
    }

    useEffect(() => {
        // When the user hits the update shipment button it 
        if (updateWasSubmitted === 1) {
            validationFunction(shipment)
        } else {
            setComponentState('validating')
            fetch("https://api.shippersedge.com/v4/shipment/" + shipment.id + "?include=all,gpsStatus,messages,logTypeNotice,documents&translate=v2", {
                "method": "GET",
                "headers": {
                    "x-api-key": "vdhy8wwjUJrwhxFpfuECTiAW"
                }
            }).then(response => response.json()).then(data => {
                validationFunction(data)
            })
                .catch(err => {
                    console.error(err);
                }
                );

        }

    }, [shipment, updateWasSubmitted])

    useEffect(() => {
        if (reasonsCantEditBilledCharges.length) {
            setEditBilledChargesDisabled(true)
        } else {
            setEditBilledChargesDisabled(false)
        }
    }, [reasonsCantEditBilledCharges])



    const handleClick = (event) => {
        event.preventDefault();
        setModalShow(true)
    }

    const handleClickWithUpdate = async (event) => {
        event.preventDefault();
        await submitUpdate();
        setModalShow(true)
    }

    const handleClose = () => {

        setModalShow(false)
    }

    const handleClickForemostWarn = (event) => {
        event.preventDefault();
        setForemostWarnShow(true)
    }

    const handleCloseForemostWarn = () => {

        setForemostWarnShow(false)
    }

    const submitUpdate = async () => {
        const buildDocumentUploadFormData = await documentUploadBuildFormData();
        const buildShipmentUpdateFormData = await buildFormDataForShipmentUpdate();
        const updateShipment = await UpdateShipmentStatus.Update(buildShipmentUpdateFormData, 5000);
        const uploadDocument1 = await UploadFile.UploadNew(
            buildDocumentUploadFormData,
            10000
        );
    }

    // Old code for reference, delete when done
    // const foremostRequiredDocsUploaded = (shipment) => {
    //     if (shipment.documents.length === 0) {
    //         return false
    //     }
    //     if (shipment.documents.filter(document => document.type === 'Signed-BOLandInvoice').length) {
    //         return true
    //     }
    //     if (shipment.documents.filter(document => document.type === 'Signed-BOL').length && shipment.documents.filter(document => document.type === 'invoice').length) {
    //         return true
    //     }
    //     return false
    // }

    // const validateFormostEditBilledRequirements = (shipment) => {
    //     // returns true if all requirements are met
    //     // returns false if any requirements are not met
    //     if (shipment.customerId === "foremost") {
    //         // Dates and status
    //         if (
    //             shipment.dates.pickupDate === "" ||
    //             shipment.dates.pickupTimeIn === "" ||
    //             shipment.dates.pickupTimeOut === "" ||
    //             shipment.dates.deliveryDate === "" ||
    //             shipment.dates.deliveryTimeIn === "" ||
    //             shipment.dates.deliveryTimeOut === "" ||
    //             shipment.status.statusId !== "DEL"
    //         ) {
    //             // console.log('checkFormostEditBilledRequirements dates or status wrong')
    //             return false
    //         }
    //         // Documents
    //         if (!shipment?.documents?.length) {
    //             // console.log('checkFormostEditBilledRequirements no documents')
    //             return false
    //         }
    //         if ((shipment.documents.filter(document => document.type === 'BOL').length) && (shipment.documents.filter(document => document.type === 'invoice').length)) {
    //             // console.log('checkFormostEditBilledRequirements BOL or invoice all good')
    //             return true
    //         }
    //         if ((shipment.documents.filter(document => document.type === 'Signed-BOL').length) && (shipment.documents.filter(document => document.type === 'invoice').length)) {
    //             // console.log('checkFormostEditBilledRequirements Signed-BOL or invoice all good')
    //             return true
    //         }
    //         if ((shipment.documents.filter(document => document.type === 'Signed-BOLandInvoice').length)) {
    //             // console.log('checkFormostEditBilledRequirements Signed-BOLandInvoice all good')
    //             return true
    //         }
    //         if ((documentUploadFileType === 'invoice' || documentUploadFileType === 'Signed-BOLandInvoice')) {
    //             // console.log('checkFormostEditBilledRequirements invoice or Signed-BOLandInvoice all good')
    //             return true
    //         }
    //     }
    //     // console.log('checkFormostEditBilledRequirements missed doc requirements false')

    //     return false
    // }

    const setComponentStateToStartAfter3Seconds = () => {
        setTimeout(() => {
            setComponentState('start')
        }, 3000);
    }



    if (componentState === "validating") {
        return (
            <>
                <div className='col-5'>
                    {/* a flex row that vetically and horizontally centers its children*/}

                    <div class="alert alert-dark border-dark d-flex align-items-center justify-content-center" role="alert">
                        <div>Checking recent billing related updates...</div>
                        <div class="spinner-border ml-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </>

        )
    }

    if (shipment.customerId) {
        return (
            <>
                {shipment.customerId.includes('halliburton') ? null : 
                    <div className='col-3 '>
                        <button type="button" class="btn btn-success" onClick={(e) => handleClick(e)} disabled={editBilledChargesDisabled}>
                            {shipment.customerId === 'kwiktrip' ? 'Dispute Billed Charges' : 'Submit Billed Charges'}
                        </button>

                        <BilledChargeEditInDetailModal
                            shipment={shipment}
                            modalShow={modalShow}
                            setModalShow={handleClose}
                            accessoralRequestFeatureExists={accessoralRequestFeatureExists}
                        />

                    </div>
                }
                <div className={shipment.customerId.includes('halliburton') ? 'col-8' : null}>
                    {reasonsCantEditBilledCharges.length > 0 ?
                        // Can't edit billed charges
                        <div class="card" >
                            <div class="card-header">
                                {shipment.customerId.includes('halliburton') ? `Please take the following (${reasonsCantEditBilledCharges.length + 1}) actions before submitting the billed charges:`
                                    : `Please take the following (${reasonsCantEditBilledCharges.length + 1}) actions to complete invoicing:`}

                                {reasonsCantEditBilledCharges.length > 3 ?
                                    <button type="button" class="btn btn-secondary btn-sm ml-3" onClick={() => setReasonsCantEditBilledChargesShowMore(!reasonsCantEditBilledChargesShowMore)}>
                                        {reasonsCantEditBilledChargesShowMore ? "Hide " : "Show "}

                                        more
                                    </button>

                                    : null
                                }
                            </div>
                            <ul class="list-group list-group-flush">
                                {reasonsCantEditBilledCharges.map((reason, index) => {
                                    // list the first 3 reasons

                                    if (index < 3) {
                                        return <li class="list-group-item" key={index}>⏺ {reason}</li>
                                    }

                                    if (index >= 3 && reasonsCantEditBilledChargesShowMore) {
                                        return <li class="list-group-item" key={index}>⏺ {reason}</li>
                                    }

                                })}
                                {reasonsCantEditBilledCharges.length <= 3 ?
                                    <li class="list-group-item" key={99}>⏺ Click "Update Shipment".</li>
                                    : null}
                                {reasonsCantEditBilledCharges.length > 3 && reasonsCantEditBilledChargesShowMore ?
                                    <li class="list-group-item" key={99}>⏺ Click "Update Shipment".</li>
                                    : null}
                            </ul>
                        </div>
                        :
                        // All good to edit billed charges
                        <div class="alert alert-success border-success w-75 text-center" role="alert">
                            {!shipment.customerId.includes("halliburton") ? "⬅ All requirements met.  You may now submit billed charges." : "All billing requirements met."}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                                <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                            </svg>
                        </div>
                    }

                </div>

                <div className='col-1'></div>
            </>

        )
    }

    // Old
    // {
    //     return (
    //         <div>
    //             {
    //                 !validateFormostEditBilledRequirements(shipment)
    //                     ?
    //                     <a href="#" onClick={(event) => { handleClickForemostWarn(event) }}>Edit Billed Charges</a>
    //                     :
    //                     (
    //                         shipment.customerId === "foremost" &&
    //                         (
    //                             shipment.dates.pickupDate !== "" &&
    //                             shipment.dates.pickupTimeIn !== "" &&
    //                             shipment.dates.pickupTimeOut !== "" &&
    //                             shipment.dates.deliveryDate !== "" &&
    //                             shipment.dates.deliveryTimeIn !== "" &&
    //                             shipment.dates.deliveryTimeOut !== "" &&
    //                             shipment.status.statusId === "DEL" &&
    //                             (
    //                                 (
    //                                     !shipment.documents.length || (
    //                                         (
    //                                             (!shipment.documents.filter(document => document.type === 'BOL').length === 0) && (!shipment.documents.filter(document => document.type === 'invoice').length === 0)
    //                                         ) ||
    //                                         (
    //                                             (!shipment.documents.filter(document => document.type === 'Signed-BOL').length === 0) && (!shipment.documents.filter(document => document.type === 'invoice').length === 0)
    //                                         ) ||
    //                                         (
    //                                             (!shipment.documents.filter(document => document.type === 'Signed-BOLandInvoice').length === 0)
    //                                         )
    //                                     )
    //                                 ) &&
    //                                 (
    //                                     (documentUploadFileType === 'BOL') || (documentUploadFileType === 'Signed-BOL') || (documentUploadFileType === 'Signed-BOLandInvoice')
    //                                 )
    //                                 &&
    //                                 !!documentUploadFile
    //                             )
    //                         )
    //                     )
    //                         ?
    //                         <a href="#" onClick={(event) => { handleClickWithUpdate(event) }}>Edit Billed Charges</a>
    //                         :
    //                         <a href="#" onClick={(event) => { handleClick(event) }}>Edit Billed Charges </a>
    //             }

    //             <br />
    //             <BilledChargeEditInDetailModal
    //                 shipment={shipment}
    //                 modalShow={modalShow}
    //                 setModalShow={handleClose}

    //                 accessoralRequestFeatureExists={accessoralRequestFeatureExists}
    //             />

    //             <ForemostBilledChargeEditInDetailWarn
    //                 shipment={shipment}
    //                 modalShow={foremostWarnShow}
    //                 setModalShow={handleCloseForemostWarn}
    //             />
    //         </div>
    //     )
    //}
}

export default BilledChargeEditInDetail
