import React, { useState } from 'react'
import { GoogleMapsLink } from '.'
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard'
import ShipperDocs from './ShipperDocs'

const PickupAddress = ({ shipment }) => {

    const [showStops, setShowStops] = useState(false)

    const showHideStops = () => {
        if (!showStops) {
            setShowStops(true)
        } else {
            setShowStops(false)
        }
    }




    if (shipment) {

        let fullAddress = shipment.shipper.name + " " + shipment.shipper.address.line1
        if (shipment.shipper.address.line2 && shipment.shipper.address.line2 !== "") {
            fullAddress = fullAddress + " " + shipment.shipper.address.line1
        }
        fullAddress = fullAddress + " " + shipment.shipper.address.city + " " + shipment.shipper.address.state + " " + shipment.shipper.address.zip

        return (
            <div>
                <span className='cardBodyRow1 font-weight-bold'>Pickup:</span><br />
                {shipment.shipper.name}<br />
                {shipment.shipper.address.line1}<br />
                {shipment.shipper.address.line2 && shipment.shipper.address.line2 !== "" ?
                    <>
                        {shipment.shipper.address.line2} <br />
                    </>

                    : null}
                {shipment.shipper.address.city},{" "}
                {shipment.shipper.address.state}{" "}
                {shipment.shipper.address.zip}<br />
                {shipment.customerId.includes("halliburton") && shipment.shipper?.coordinates?.latitude && shipment.shipper?.coordinates?.longitude ?
                    <div>
                        Coordinates: {shipment.shipper.coordinates.latitude}, {shipment.shipper.coordinates.longitude}
                    </div>
                    : null}
                <div className='d-flex'>
                    <CopyToClipboard text={fullAddress} color={"#4c8bf5"} message={'Copied Pickup Address to Clipboard'} />
                    <div className='mr-1'></div>
                    <GoogleMapsLink address={fullAddress} />
                </div>
                <div>
                    <ShipperDocs shipment={shipment} />
                </div>
                {shipment.stops && shipment.stops.length > 2 ? (
                    <>
                        <button className='mt-2' onClick={() => { showHideStops() }}>
                            Stops ({shipment.stops.length})</button>
                    </>
                ) : (null)

                }
                {shipment.stops && shipment.stops.length > 2 && showStops ? (
                    <>

                        {shipment.stops.map((stop, index) => {

                            return (
                                <div key={`stopAddrs-${index}`} className="card mt-2 mb-2">
                                    <div className="card-body">
                                        <span className="bold">Stop {index + 1} ({stop.type}):</span>
                                        <br />
                                        {stop.name}
                                        <br />
                                        {stop.address.line1}
                                        <br />
                                        {stop.address.line2 ? (
                                            <div>
                                                {" "}
                                                {stop.address.line2} <br />{" "}
                                            </div>
                                        ) : null}
                                        {stop.address.city} {stop.address.state}{" "}
                                        {stop.address.zip}
                                        <br />
                                        {stop.bolNotes && stop.bolNotes !== "" ? (
                                            <div>
                                                {" "}
                                                <br />
                                                <span className=""> Notes: {stop.bolNotes}</span>
                                                <br />
                                            </div>
                                        ) : null}
                                        {stop.contact.name && stop.contact.name !== "" ? (
                                            <>
                                                {" "}
                                                <br />
                                                <span className="">
                                                    {" "}
                                                    Name: {stop.contact.name}
                                                </span>
                                            </>
                                        ) : null}
                                        {stop.contact.phone && stop.contact.phone !== "" ? (
                                            <>
                                                {" "}
                                                <br />
                                                <span className="">
                                                    {" "}
                                                    Phone: {stop.contact.phone}
                                                </span>
                                            </>
                                        ) : null}
                                        {stop.contact.email && stop.contact.email !== "" ? (
                                            <>
                                                {" "}
                                                <br />
                                                <span className="">
                                                    {" "}
                                                    Email: {stop.contact.email}
                                                </span>
                                            </>
                                        ) : null}
                                        {stop.contact.fax && stop.contact.fax !== "" ? (
                                            <>
                                                {" "}
                                                <br />
                                                <span className="">
                                                    {" "}
                                                    Fax: {stop.contact.fax}
                                                </span>
                                            </>
                                        ) : null}

                                        {stop?.orders?.length > 0 ?
                                            <>
                                                Orders:
                                                <ul>
                                                    {stop.orders.map((order, index) => {
                                                        return (
                                                            <li key={`order-${index}`}>
                                                                {order.usercontrol}
                                                            </li>)
                                                    })}

                                                </ul>
                                            </>
                                            : null}
                                    </div>

                                </div>
                            );
                        })}

                    </>) : null}
            </div>
        )
    }
    else { return null }
}

export default PickupAddress
