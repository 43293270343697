// ./APIs/AcceptShipment.js

const AcceptShipment = {
  async AcceptShipment(shipmentId, secureId) {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    let fetchResult = await fetch("https://api.shippersedge.com/v4/shipment/" + shipmentId + "/acceptTender?secureId=" + secureId, requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.warn('AcceptShipment error', error))

    return fetchResult;
  }
};
export default AcceptShipment;


