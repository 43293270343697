import React, { Component } from "react";
import * as moment from "moment";
import "./PickupDelDatesTimesStatic01.css";
import DateChecker from "../../../CustLogicAndFilters/DateChecker";

class PickupDelDatesTimesStatic01 extends Component {



  constructor() {
    super();
    this.state = {};
  }

  condRenderDatesTimes() {
    // console.log('wut', this.props)
    //Pickup mode and appointmentpickupdate exists
    if (this.props.shipment) {
      if (
        this.props.mode === "pickup" &&
        this.props.shipment.dates.appointmentPickupDate &&
        DateChecker(this.props.shipment.dates.appointmentPickupDate) &&
        this.props.shipment.dates.appointmentPickupDate !== null &&
        this.props.shipment.dates.appointmentPickupDate !== ""
      ) {
        return (
          <span>
            <span className="appointment">Appointment:</span>
            <br />
            {moment(this.props.shipment.dates.appointmentPickupDate).format("MMM Do YYYY")}
            <br />

            {this.props.shipment.dates.appointmentPickupTimeIn === null || this.props.shipment.dates.appointmentPickupTimeIn === "00:00"
              ? ""
              : this.props.shipment.dates.appointmentPickupTimeIn}
          </span>
        );
      }
      //Pickup mode and appointmentpickupdate does not exist, use availabledate 1 and maybe 2
      else if (this.props.mode === "pickup" && this.props.shipment.dates.availableDate1) {
        return (
          <span>
            {this.props.shipment.dates.availableDate1 === null
              ? ""
              : moment(this.props.shipment.dates.availableDate1).format("MMM Do YYYY")}
            {/* Sometimes availpickupdate 1 and 2 are the same, no need to double dispaly them, only rendering 2 if 1 and 2 are different */}
            {this.props.shipment.dates.availableDate2 === null ||
              this.props.shipment.dates.availableDate2 === this.props.shipment.dates.availableDate1
              ? ""
              : " - " + moment(this.props.shipment.dates.availableDate2).format("MMM Do YYYY")}
            <br />
            {this.props.shipment.dates.appointmentPickupTimeIn !== null && this.props.shipment.dates.appointmentPickupTimeIn !== "00:00"
              ? (this.props.shipment.dates.appointmentPickupTimeIn)
              : ""}
          </span>
        );
      }
      //Delivery mode and there is an appointment delivery date
      else if (
        this.props.mode === "delivery" &&
        this.props.shipment.dates.appointmentDeliveryDate &&
        DateChecker(this.props.shipment.dates.appointmentDeliveryDate) &&
        this.props.shipment.dates.appointmentDeliveryDate !== null &&
        this.props.shipment.dates.appointmentDeliveryDate !== ""
      ) {

        return (
          <span>
            <span className="appointment">Appointment:</span>
            <br />
            {moment(this.props.shipment.dates.appointmentDeliveryDate).format("MMM Do YYYY")}
            <br />
            {this.props.shipment.dates.appointmentDeliveryTimeIn === null
              ? ""
              : this.props.shipment.dates.appointmentDeliveryTimeIn}
          </span>
        );
      }
      else if (
        this.props.mode === "delivery" &&
        DateChecker(this.props.shipment.dates.requestedDeliveryDate) &&
        this.props.shipment.dates.requestedDeliveryDate !== null &&
        this.props.shipment.dates.requestedDeliveryDate !== ""
      ) {
        return (
          <>
            <span>
              {moment(this.props.shipment.dates.requestedDeliveryDate).format("MMM Do YYYY")}
            </span>
            <br />
          </>
          
        );
        // Schuff uses a custom field for some (not all) consolidation "delivery by" dates, accounting for that here.
      } else if (
        this.props.mode === "delivery" &&
        (localStorage.getItem("carrierId").includes("schuff") || localStorage.getItem("carrierId").includes("graywolf")) &&
        this.props.customFields.filter(
          (customFieldObj) => customFieldObj.fieldId === 117
        )
      ) {
        return this.props.customFields
          .filter((customFieldObj) => customFieldObj.fieldId === 117)
          .map((customFieldObj) => {
            // console.log('customFieldOBJ', customFieldObj)
            if (customFieldObj.value && customFieldObj.value !== '') {
              return (
                <span>{moment(customFieldObj.value).format("MMM Do YYYY")}</span>
              );
            } else {
              return null
            }

          });
      }
      //Delivery mode and there is not an appointment delivery date try just deliveryDate
      else if (
        this.props.mode === "delivery" &&
        DateChecker(this.props.shipment.dates.deliveryDate) &&
        this.props.shipment.dates.deliveryDate !== null &&
        this.props.shipment.dates.deliveryDate !== ""
      ) {
        return (
          <span>{moment(this.props.shipment.dates.deliveryDate).format("MMM Do YYYY")}</span>
        );
        // Delivery mode destAvailableDate1 and 2
      } else if (
        this.props.mode === "delivery" &&
        DateChecker(this.props.shipment.dates.destAvailableDate1) &&
        this.props.shipment.dates.destAvailableDate1 !== null &&
        this.props.shipment.dates.destAvailableDate1 !== ""
      ) {
        return (
          <>
            <span>{moment(this.props.shipment.dates.destAvailableDate1).format("MMM Do YYYY")}</span><br />
            {moment(this.props.shipment.dates.destAvailableDate1).format("MMM Do YYYY") !== moment(this.props.shipment.dates.destAvailableDate2).format("MMM Do YYYY") ? <span>{moment(this.props.shipment.dates.destAvailableDate2).format("MMM Do YYYY")}</span> : null}
          </>
        )
      } else {
        return null;
      }
    }
  }

    renderTimeRange = (mode, dates) => {
      if (!dates || !dates.openTime || !dates.closeTime) return null;
  
      const openTime = moment(dates.openTime, 'HH:mm:ss').format('hh:mm A');
      const closeTime = moment(dates.closeTime, 'HH:mm:ss').format('hh:mm A');
  
      return (
        <>
          <p className="pickup-delivery">{`${mode.charAt(0).toUpperCase() + mode.slice(1)} Hours:`}</p>
          <span style={{ whiteSpace: 'nowrap' }}>
            {`${openTime} - ${closeTime}`}
          </span>
        </>
      );
    };

  render() {
    return (
      <span>
        {this.condRenderDatesTimes()}
        <>
          {this.props.mode === "pickup" &&
            this.renderTimeRange("pickup", this.props.shipment?.shipper?.dates)}
          {this.props.mode === "delivery" &&
            this.renderTimeRange("delivery", this.props.shipment?.consignee?.dates)}
        </>
      </span>
    );
  }
}
export default PickupDelDatesTimesStatic01;
