import React from 'react'
import Form from "react-bootstrap/Form";

const TrackingInvoiceNumber = ({ showOrHide, clientCode, trackingNumber, shipmentObjUpdateEvent }) => {
    console.log(showOrHide)
    if (showOrHide === "show" || showOrHide === "hilight") {
        return (

            <div className={`d-inline-block ${showOrHide}`}>
                {(clientCode === 'kwiktrip' || clientCode.includes('halliburton')) ? "Pro Number:" : "Invoice " + (clientCode !== 'Schuff' && clientCode !== 'graywolf' ? " / Pro Number:" : " Number:")}
                <br />
                <Form.Control
                    size="sm"
                    type="text"
                    name="charges.trackingNumber"
                    value={
                        trackingNumber === null
                            ? ""
                            : trackingNumber
                    }
                    onChange={(event) => shipmentObjUpdateEvent(event)}
                />
            </div>

        )
    } else { return null }
}

export default TrackingInvoiceNumber
