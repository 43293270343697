// ./Components/Navbar.js
// Version 3 of the navbar because react-bootstrap has had some odd issues and this one will be in Bootstrap 4.3.1 and styled by the css file and not react-bootstrap
// import React, { Component } from "react";
import React from "react";
import UserContext from "../../UserDataContext";
import "./Navbar.css";
import PrimaryBtn from "../../SmallComponents/PrimaryBtn";
import ProfileModal from "../../Components/ProfileModal";
import NavNotifcations from "./Components/NavNotifcations";
import ConsolidationModal from "../Consolidation/ConsolidationModal";

// let style1 = { position: "relative" };
// let style2 = { position: "absolute", bottom: "0" };

class Navbar extends React.Component {
  static contextType = UserContext;
  constructor() {
    super();
    this.handleChangeGeneric = this.handleChangeGeneric.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleClearSubmit = this.handleClearSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.modalOpen = this.modalOpen.bind(this);
    this.onHideConsolidationModal = this.onHideConsolidationModal.bind(this);
    this.onOpenConsolidationModal = this.onOpenConsolidationModal.bind(this);
    this.condRenderLogoName = this.condRenderLogoName.bind(this);
    this.onEnterKeyPress = this.onEnterKeyPress.bind(this);
    this.handleChangeSearchTerm = this.handleChangeSearchTerm.bind(this);
    this.handleCarrierChange = this.handleCarrierChange.bind(this);

    this.state = {
      profileModalShow: false,
      consolidationModalShow: false,
      searchTerm: "allnums",
      searchVal: "",
      searchPlaceHolderValue: "Search All Shipments",
      searchType: "text",
    };
  }

  onHide() {
    this.setState({ profileModalShow: false });
  }

  modalOpen() {
    this.setState({ profileModalShow: true });
  }

  onHideConsolidationModal() {
    this.setState({ consolidationModalShow: false });
  }

  onOpenConsolidationModal() {
    this.setState({ consolidationModalShow: true });
  }

  handleChangeGeneric(event) {
    // console.log("handleChangeGeneric", event.target.name, event.target.value)
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value }, () => {
      let requiredCharactersBeforeAutoSearch = 4;
      if (
        this.state.searchTerm === "allnums" &&
        this.state.searchVal.length > requiredCharactersBeforeAutoSearch
      ) {
        // console.log('serchval', this.state.searchVal)
        this.handleSubmitSearch();
      } else {
        this.props.clearSearchResults();
      }
    });
  }

  handleChangeSearchTerm(event) {
    event.preventDefault();
    this.setState({ searchVal: "" });
    if (event.target.value === "allnums") {
      this.setState({
        searchPlaceHolderValue: "Search All Shipments",
        searchType: "text",
        searchTerm: "allnums",
      });
    }
    if (event.target.value === "pudate") {
      this.setState({
        searchPlaceHolderValue: "YYYY-MM-DD",
        searchType: "date",
        searchTerm: "pudate",
      });
    }
    if (event.target.value === "deldate") {
      this.setState({
        searchPlaceHolderValue: "YYYY-MM-DD",
        searchType: "date",
        searchTerm: "deldate",
      });
    }
    // if (event.target.value === "deldate") {
    //   this.setState({ searchPlaceHolderValue: "YYYY-MM-DD" });
    // }
    if (event.target.value === "pucity") {
      this.setState({
        searchPlaceHolderValue: "New York",
        searchType: "text",
        searchTerm: "pucity",
      });
    }
    if (event.target.value === "delcity") {
      this.setState({
        searchPlaceHolderValue: "Los Angeles",
        searchType: "text",
        searchTerm: "delcity",
      });
    }
    if (event.target.value === "status") {
      this.setState({
        searchPlaceHolderValue: "DEL",
        searchType: "status",
        searchTerm: "status",
        searchVal: "DEL",
      });
    }
    if (event.target.value === "settledate") {
      this.setState({
        searchPlaceHolderValue: "YYYY-MM-DD",
        searchType: "date",
        searchTerm: "settledate",
      });
    }
  }

  handleSubmitSearch(event) {
    if (event) {
      event.preventDefault();
    }
    this.props.clearSearchResults();
    this.props.setSearchTerm(this.state.searchTerm);
    this.props.setSearchVal(this.state.searchVal);
  }

  handleClearSubmit(event) {
    event.preventDefault();
    if (this.state.searchType === "status") {
    } else {
      this.setState({ searchVal: "" });
    }
    this.props.clearSearchResults();
  }

  handleCarrierChange(event) {
    this.props.changeCarrier(event.target.value);
  }

  onEnterKeyPress(event) {
    if (event.which === 13) {
      this.handleSubmitSearch(event);
    }
  }

  refreshShipments() {
    this.props.refreshShipments();
  }

  condRenderLogoName() {
    if (this.props.userData2.length) {
      if (this.props.userData2.length > 1) {
        this.props.userData2.sort((a, b) =>
          a.carrierId > b.carrierId ? 1 : -1
        );
        return (
          <div className=" form-inline flex-fill justify-content-start mr-3">
            <img
              src={localStorage.getItem("clientLogo")}
              alt="logo"
              height="40vh"
              style={{ maxWidth: 300 }}
            />
            <select
              id="broker-select-options"
              className="form-control form-control-sm ml-1"
              // disabled={!this.props.searchEnable}
              onChange={this.handleCarrierChange}
            >
              {this.props.userData2.map((carrierCustomer) => {
                // This maintains the selected value if someone hits refresh in the browser or it the dropdown will reset in the browser dom and get out of sync with the drop down
                if (this.props.currentCarrierId === carrierCustomer.carrierId) {
                  return (
                    <option
                      key={carrierCustomer.carrierId}
                      value={carrierCustomer.carrierId}
                      selected
                    >
                      {" "}
                      {carrierCustomer.clientName} Carrier Portal - Welcome{" "}
                      {carrierCustomer.userName} {carrierCustomer.carrierName}{" "}
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={carrierCustomer.carrierId}
                      value={carrierCustomer.carrierId}
                    >
                      {" "}
                      {carrierCustomer.clientName} Carrier Portal - Welcome{" "}
                      {carrierCustomer.userName} {carrierCustomer.carrierName}{" "}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        );
      } else {
        let clientLogo = this.props.userData2[0].clientLogo;
        let clientName = this.props.userData2[0].clientName;
        let carrierName = this.props.userData2[0].carrierName;
        let userName = this.props.userData2[0].name;
        return (
          <div className=" flex-fill justify-content-start">
            {" "}
            <img src={clientLogo} alt="logo" height="40vh" />{" "}
            <span>
              {clientName} Carrier Portal - Welcome {userName} - {carrierName}
            </span>{" "}
          </div>
        );
      }
    } else {
      return (
        <div className=" flex-fill justify-content-start">
          {" "}
          <img
            src="se-icon-310x310.png"
            alt="shippersedge logo"
            height="25vh"
          />{" "}
          <span>ShippersEdge TMS</span>
        </div>
      );
    }
  }



  render() {
    return (
      <nav className="nav fixed-top ">
        <div className="container ">
          <div className="row d-flex navRowTop">
            {/* Top Left */}
            {this.condRenderLogoName()}
            {/* Top Right */}
            <div className="justify-content-end">
              <NavNotifcations />
              <PrimaryBtn
                btnName="Profile"
                showHide="show"
                onClick={this.modalOpen}
              />

              <ProfileModal
                show={this.state.profileModalShow}
                onHide={this.onHide}
              />
            </div>
          </div>

          <div className="navRowBottom row d-flex">
            {/* Bottom Right */}
            <div className="form-group d-flex justify-content-end">
              <select
                className="form-control form-control-sm"
                disabled={!this.props.searchEnable}
                as="select"
                size="sm"
                name="searchTerm"
                onChange={this.handleChangeSearchTerm}
              >
                <option defaultValue="allnums" value="allnums">
                  All Reference Numbers
                </option>
                <option value="pudate">Pick Up Date</option>
                <option value="deldate">Delivered Date</option>
                <option value="pucity">Pick Up City</option>
                <option value="delcity">Delivered City</option>
                <option value="status">Status</option>
                <option value="settledate">Settlement Date</option>
              </select>
            </div>
            <div className="form-group d-flex justify-content-end">
              {this.state.searchType === "text" ? (
                <input
                  className="form-control form-control-sm navMarginLeft mr-1"
                  disabled={!this.props.searchEnable}
                  size="sm"
                  type="text"
                  placeholder={this.state.searchPlaceHolderValue}
                  name="searchVal"
                  value={this.state.searchVal}
                  onChange={this.handleChangeGeneric}
                  onKeyPress={this.onEnterKeyPress}
                />
              ) : null}
              {this.state.searchType === "date" ? (
                <input
                  className="form-control form-control-sm navMarginLeft mr-1"
                  disabled={!this.props.searchEnable}
                  size="sm"
                  type="date"
                  placeholder={this.state.searchPlaceHolderValue}
                  name="searchVal"
                  value={this.state.searchVal}
                  onChange={this.handleChangeGeneric}
                  onKeyPress={this.onEnterKeyPress}
                />
              ) : null}
              {/* Status is in progress */}
              {this.state.searchType === "status" ? (
                <select
                  className="form-control form-control-sm navMarginLeft mr-1"
                  disabled={!this.props.searchEnable}
                  size="sm"
                  type="date"
                  placeholder={this.state.searchPlaceHolderValue}
                  name="searchVal"
                  value={this.state.searchVal}
                  onChange={this.handleChangeGeneric}
                  onKeyPress={this.onEnterKeyPress}
                >
                  <option value="DEL">Delivered</option>
                  <option value="DSP">Dispatched</option>
                  <option value="IT">In Transit</option>
                  <option value="OFD">Out For Delivery</option>
                  <option value="NEED-REVIEW">Need Review</option>
                </select>
              ) : null}
            </div>
            <div className="form-group  d-flex justify-content-end">
              <PrimaryBtn
                showHide="show"
                extraclassName="navMarginLeft"
                btnName="Search"
                disabled={!this.props.searchEnable}
                onClick={this.handleSubmitSearch}
              />
              <PrimaryBtn
                showHide="show"
                extraclassName="navMarginLeft"
                btnName="&nbsp;Clear&nbsp;"
                disabled={!this.props.searchEnable}
                onClick={this.handleClearSubmit}
              />
              {/* Consolidation is disabled for now */}
              {this.context?.clientCode === "exportpackersXXX" ?
                <>
                  <PrimaryBtn
                    btnName="Add Consolidation"
                    showHide="show"
                    onClick={this.onOpenConsolidationModal}
                  />
                  <ConsolidationModal
                    show={this.state.consolidationModalShow}
                    onHide={this.onHideConsolidationModal}
                  />
                </>
                : null}



              <br />
              {/* {this.context !== undefined ? (
                <span>{this.context.id}</span>
              ) : null} */}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
