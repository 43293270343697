import React from 'react'
import PrimaryBtn from '../../../SmallComponents/PrimaryBtn'

const ShipmentStatus = ({ shipment, statusBtns, shipmentObjUpdateEvent, statusBtnsHilightHide, statusBtnShowHidePuOut, statusBtnShowHideIT, statusBtnShowHideITDelayed, statusBtnShowHideOFD, statusBtnShowHideDEL }) => {
    if (statusBtns === "show" || statusBtns === "hilight") {
        return (
            <fieldset className="border p-2 d-inline-block">
                <legend className="w-auto">Shipment Status</legend>
                <PrimaryBtn
                    btnName="Picked Up"
                    value="PU-Out"
                    showHide={statusBtnShowHidePuOut}
                    name="status.statusId"
                    extraClass={
                        shipment.status.statusId === "PU-Out"
                            ? "btn-sm btn btn-outline-dark statusButtons active"
                            : "btn-sm btn btn-outline-dark statusButtons"
                    }
                    onClick={(event) => shipmentObjUpdateEvent(event)}
                />
                <PrimaryBtn
                    btnName="In Transit"
                    value="IT"
                    showHide={statusBtnShowHideIT}
                    name="status.statusId"
                    extraClass={
                        shipment.status.statusId === "IT"
                            ? "btn-sm btn btn-outline-dark statusButtons active"
                            : "btn-sm btn btn-outline-dark statusButtons"
                    }
                    onClick={(event) => shipmentObjUpdateEvent(event)}
                />
                <PrimaryBtn
                    btnName="Transit Delay"
                    value="IT-DELAYED"
                    showHide={statusBtnShowHideITDelayed}
                    name="status.statusId"
                    extraClass={
                        shipment.status.statusId === "IT-DELAYED"
                            ? "btn-sm btn btn-outline-dark statusButtons active"
                            : "btn-sm btn btn-outline-dark statusButtons"
                    }
                    onClick={shipmentObjUpdateEvent}
                />
                <PrimaryBtn
                    btnName="Out for Delivery"
                    value="OFD"
                    showHide={statusBtnShowHideOFD}
                    name="status.statusId"
                    extraClass={
                        shipment.status.statusId === "OFD"
                            ? "btn-sm btn btn-outline-dark statusButtons active"
                            : "btn-sm btn btn-outline-dark statusButtons"
                    }
                    onClick={shipmentObjUpdateEvent}
                />
                <PrimaryBtn
                    btnName="Delivered"
                    value="DEL"
                    showHide={statusBtnShowHideDEL}
                    name="status.statusId"
                    extraClass={
                        shipment.status.statusId === "DEL"
                            ? "btn-sm btn btn-outline-dark statusButtons active"
                            : "btn-sm btn btn-outline-dark statusButtons"
                    }
                    onClick={shipmentObjUpdateEvent}
                />
            </fieldset>)
    } else {
        return null
    }


}

export default ShipmentStatus
