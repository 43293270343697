// ./Pages/GenericCollection.js

import React from "react";
import ShipmentCard2 from "../Components/ShipmentCard2";
import RateRequestCard from "../Components/RateRequestCard/RateRequestCard";
import Collapse from "react-bootstrap/Collapse";
import GenricCollectionMessage from "../Components/WelcomeMessages/GenericCollectionMessage";
import "./GenericCollection.css";
import MailIcon from "../SmallComponents/MailIcon";
import UserContext from "../UserDataContext";
import { CSVLink } from "react-csv";

class GenericCollection extends React.Component {
  static defaultProps = {
    shipData: { data: [] },
    shipments: [],
    filter: "",
  };
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.condrenderShipmentCard = this.condrenderShipmentCard.bind(this);
    this.openAndIconChange = this.openAndIconChange.bind(this);
    this.GenricCollectionMessageClose =
      this.GenricCollectionMessageClose.bind(this);
    this.hashForReactKey = this.hashForReactKey.bind(this);
    this.delayedRenderClick = this.delayedRenderClick.bind(this);
    this.sortMachine = this.sortMachine.bind(this);
    this.changeSortOrder = this.changeSortOrder.bind(this);
    this.handleChangeSortType = this.handleChangeSortType.bind(this);
    this.condrenderRateQuoteCard = this.condrenderRateQuoteCard.bind(this);
    this.checkForNewMessages = this.checkForNewMessages.bind(this);
    this.statupAndUpDateLogic = this.statupAndUpDateLogic.bind(this);
    this.sortMachineNormalizedData = this.sortMachineNormalizedData.bind(this);
    this.convertToStandardTime = this.convertToStandardTime.bind(this);
    this.getCSVData = this.getCSVData.bind(this);

    this.state = {
      delayedRenderState: false,
      currentSortOrder: "Descending",
      currentSortItem: "BOL",
      open: false,
      plusMinus: "fas fa-plus",
      enableDisable: "catagoryDivEnabled shadow",
      messageShow: "show",
      currentShipmentSort: 1,
      sortByBolA: "(shipment1, shipment2) => shipment1.id > shipment2.id",
      sortByBolD: "(shipment1, shipment2) => shipment1.id < shipment2.id",
      sortByPickupA:
        "(shipment1, shipment2) => Moment(shipment1.appointmentPickupDate).format('YYYYMMDD') < Moment(shipment2.appointmentPickupDate).format('YYYYMMDD')",
      results: [],
      messagesIcon: "hide",
      csvData: [[], []],
    };
  }

  componentDidMount() {
    this.hashForReactKey();
    this.statupAndUpDateLogic();
  }

  // Startup and update logic
  statupAndUpDateLogic() {
    let date = new Date();
    let thrityDaysAgoNum = new Date().setDate(date.getDate() - 30);
    // console.log("minus30", thrityDaysAgoNum);
    // console.log("minus30-2", new Date("2020-08-04T12:37:18+00:00").getTime());
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let dayOfMonth = ("0" + date.getDate()).slice(-2);
    let today = date.getFullYear() + "-" + month + "-" + dayOfMonth;
    // console.log('today', today)
    let date2 = new Date();
    date2.setDate(date2.getDate() + 1);
    let month2 = ("0" + (date2.getMonth() + 1)).slice(-2);
    let dayOfMonth2 = ("0" + date2.getDate()).slice(-2);
    let tomorrow = date2.getFullYear() + "-" + month2 + "-" + dayOfMonth2;
    if (this.props.type === "rates" && this.props.shipData) {
      let requests = this.props.shipData;

      this.setState({ results: requests }, () => this.sortMachine("BOL"));

      this.setState({ totalRequests: requests.length });
      if (requests.length) {
        this.setState({ enableDisable: "catagoryDivEnabled shadow" });
        this.setState({
          messageShow: localStorage.getItem(`${this.props.id}message`),
        });
        if (requests.length && !this.state.open) {
          this.setState({ plusMinus: "fas fa-plus" });
        }
        this.checkForNewMessages(requests);
      } else {
        this.setState({
          plusMinus: "",
          messagesIcon: "hide",
          enableDisable: "catagoryDivDisabled",
          messageShow: localStorage.getItem(`${this.props.id}message`),
        });
      }
    }

    if (this.props.type !== "rates") {
      // Here is where the configured filter is applied
      // console.log('filter', this.props.filter)
      let testFilter =
        "shipment => (shipment.status.statusId !== 'AwaitingQuotes' && shipment.status.statusId !== 'DSP-AA' && (shipment.loadDetails?.dockSchedulerQualification?.qualified === true && shipment.loadDetails?.dockSchedulerQualification?.needsAppointmentScheduled === true ))";
      let useTestFilter = false;
      let requests2;
      if (useTestFilter) {
        requests2 = this.props.shipments.filter(eval(testFilter));
      } else {
        requests2 = this.props.shipments.filter(eval(this.props.filter));
      }
      // console.log("startupAndUPdate requests2", requests2);
      this.setState({ shipments: requests2 }, () =>
        this.sortMachineNormalizedData("BOL")
      );

      this.setState({ totalRequests: requests2.length });
      if (requests2.length) {
        this.setState({ enableDisable: "catagoryDivEnabled shadow" });
        this.setState({
          messageShow: localStorage.getItem(`${this.props.id}message`),
        });
        if (requests2.length && !this.state.open) {
          this.setState({ plusMinus: "fas fa-plus" });
        }
        this.checkForNewMessages(requests2);
      } else {
        this.setState({
          plusMinus: "",
          messagesIcon: "hide",
          enableDisable: "catagoryDivDisabled",
          messageShow: localStorage.getItem(`${this.props.id}message`),
        });
      }
    }
  }

  sortMachine(sortType) {
    if (sortType === "BOL") {
      if (this.state.currentSortOrder === "Ascending") {
        const resortedData = this.state.results.sort(
          (shipment1, shipment2) => shipment1.id > shipment2.id
        );
        this.setState({ shipData: resortedData });
      }
      if (this.state.currentSortOrder === "Descending") {
        const resortedData = this.state.results.sort(
          (shipment1, shipment2) => shipment1.id < shipment2.id
        );
        this.setState({ shipData: resortedData });
      }
    }
    //This applies to all pickup dates
    if (sortType === "puApptDate") {
      // console.log("SortMachine, puApptDate", this.state.currentSortOrder);
      const resortedData = this.state.results.sort(
        (shipment1, shipment2, sortOrder) => {
          if (
            shipment1.dates.appointmentPickupDate === "" &&
            shipment2.dates.appointmentPickupDate !== ""
          ) {
            let shipment1Date1 = shipment1.dates.availableDate1;
            // console.log("found date1", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1Date1) -
                new Date(shipment2.dates.appointmentPickupDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentPickupDate) -
                new Date(shipment1Date1)
              );
            }
          }
          if (
            shipment2.dates.appointmentPickupDate === "" &&
            shipment1.dates.appointmentPickupDate !== ""
          ) {
            // console.log("found date2", this.state.currentSortOrder);
            let shipment2Date1 = shipment2.dates.availableDate1;
            // console.log("found date2 using", shipment2Date1);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentPickupDate) -
                new Date(shipment2Date1)
              );
            } else {
              return (
                new Date(shipment2Date1) -
                new Date(shipment1.dates.appointmentPickupDate)
              );
            }
          }
          if (
            shipment2.dates.appointmentPickupDate === "" &&
            shipment1.dates.appointmentPickupDate === ""
          ) {
            // console.log("found date3", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.availableDate1) -
                new Date(shipment2.dates.availableDate1)
              );
            } else {
              return (
                new Date(shipment2.dates.availableDate1) -
                new Date(shipment1.dates.availableDate1)
              );
            }
          } else {
            // console.log("found date4", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentPickupDate) -
                new Date(shipment2.dates.appointmentPickupDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentPickupDate) -
                new Date(shipment1.dates.appointmentPickupDate)
              );
            }
          }
        }
      );
      this.setState({ shipData: resortedData });
    }

    if (sortType === "deliveryDate") {
      const resortedData = this.state.results.sort(
        (shipment1, shipment2, sortOrder) => {
          if (
            (shipment1.dates.appointmentDeliveryDate ===
              "0001-01-01T00:00:00" ||
              shipment1.dates.appointmentDeliveryDate === "" ||
              shipment1.dates.appointmentDeliveryDate === null) &&
            (shipment2.dates.appointmentDeliveryDate !==
              "0001-01-01T00:00:00" ||
              shipment2.dates.appointmentDeliveryDate !== "" ||
              shipment2.dates.appointmentDeliveryDate !== null)
          ) {
            let shipment1Date1 = shipment1.dates.deliveryDate;
            // console.log("found date1", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1Date1) -
                new Date(shipment2.dates.appointmentDeliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentDeliveryDate) -
                new Date(shipment1Date1)
              );
            }
          }
          if (
            (shipment2.dates.appointmentDeliveryDate ===
              "0001-01-01T00:00:00" ||
              shipment2.dates.appointmentDeliveryDate === "" ||
              shipment2.dates.appointmentDeliveryDate === null) &&
            (shipment1.dates.appointmentDeliveryDate !==
              "0001-01-01T00:00:00" ||
              shipment1.dates.appointmentDeliveryDate !== "" ||
              shipment1.dates.appointmentDeliveryDate !== null)
          ) {
            // console.log("found date2", this.state.currentSortOrder);
            let shipment2Date1 = shipment2.dates.deliveryDate;
            // console.log("found date2 using", shipment2Date1);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentDeliveryDate) -
                new Date(shipment2Date1)
              );
            } else {
              return (
                new Date(shipment2Date1) -
                new Date(shipment1.dates.appointmentDeliveryDate)
              );
            }
          }
          if (
            (shipment2.dates.appointmentDeliveryDate ===
              "0001-01-01T00:00:00" ||
              shipment2.dates.appointmentDeliveryDate === "" ||
              shipment2.dates.appointmentDeliveryDate === null) &&
            (shipment1.dates.appointmentDeliveryDate ===
              "0001-01-01T00:00:00" ||
              shipment1.dates.appointmentDeliveryDate === "" ||
              shipment1.dates.appointmentDeliveryDate === null)
          ) {
            // console.log("found date3", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.deliveryDate) -
                new Date(shipment2.dates.deliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.deliveryDate) -
                new Date(shipment1.dates.deliveryDate)
              );
            }
          } else {
            // console.log("found date4", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentDeliveryDate) -
                new Date(shipment2.dates.appointmentDeliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentDeliveryDate) -
                new Date(shipment1.dates.appointmentDeliveryDate)
              );
            }
          }
        }
      );
      this.setState({ shipData: resortedData });
    }
  }

  changeSortOrder() {
    if (this.state.currentSortOrder === "Ascending") {
      this.setState(
        { currentSortOrder: "Descending" },
        () => this.sortMachine(this.state.currentSortItem),
        this.sortMachineNormalizedData(this.state.currentSortItem)
      );
    } else {
      this.setState(
        { currentSortOrder: "Ascending" },
        () => this.sortMachine(this.state.currentSortItem),
        this.sortMachineNormalizedData(this.state.currentSortItem)
      );
    }
  }

  handleChangeSortType(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.sortMachine(this.state.currentSortItem)
    );
  }

  checkForNewMessages(requests) {
    if (
      requests !== null &&
      requests.length &&
      requests !== undefined &&
      this.props.type !== "rates"
    ) {
      // console.log("checkForNewMessages stage 1 A", requests);
      let requests2 = requests.filter((shipment) => shipment.log !== null);
      let requests3 = requests2.filter(
        (shipment) =>
          shipment.log.filter(
            (log) =>
              log.type === "Message" &&
              log.read === 0 &&
              log.usercode !== "Carrier"
          ).length
      );

      if (requests3.length) {
        this.setState({
          messagesIcon: "unreadMessagesCollection",
        });
      } else {
        this.setState({
          messagesIcon: "hide",
        });
      }
    }
  }

  sortMachineNormalizedData(sortType) {
    if (sortType === "BOL") {
      if (this.state.currentSortOrder === "Ascending") {
        const resortedData = this.state.shipments.sort(
          (shipment1, shipment2) => shipment1.id > shipment2.id
        );
        this.setState({ shipments: resortedData });
      }
      if (this.state.currentSortOrder === "Descending") {
        const resortedData = this.state.shipments.sort(
          (shipment1, shipment2) => shipment1.id < shipment2.id
        );
        this.setState({ shipments: resortedData });
      }
    }
    //This applies to all pickup dates
    if (sortType === "puApptDate") {
      // console.log("SortMachine, puApptDate", this.state.currentSortOrder);
      const resortedData = this.state.shipments.sort(
        (shipment1, shipment2, sortOrder) => {
          if (
            shipment1.dates.appointmentPickupDate === "" &&
            shipment2.dates.appointmentPickupDate !== ""
          ) {
            let shipment1Date1 = shipment1.dates.availableDate1;
            // console.log("found date1", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1Date1) -
                new Date(shipment2.dates.appointmentPickupDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentPickupDate) -
                new Date(shipment1Date1)
              );
            }
          }
          if (
            shipment2.dates.appointmentPickupDate === "" &&
            shipment1.dates.appointmentPickupDate !== ""
          ) {
            // console.log("found date2", this.state.currentSortOrder);
            let shipment2Date1 = shipment2.dates.availableDate1;
            // console.log("found date2 using", shipment2Date1);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentPickupDate) -
                new Date(shipment2Date1)
              );
            } else {
              return (
                new Date(shipment2Date1) -
                new Date(shipment1.dates.appointmentPickupDate)
              );
            }
          }
          if (
            shipment2.dates.appointmentPickupDate === "" &&
            shipment1.dates.appointmentPickupDate === ""
          ) {
            // console.log("found date3", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.availableDate1) -
                new Date(shipment2.dates.availableDate1)
              );
            } else {
              return (
                new Date(shipment2.dates.availableDate1) -
                new Date(shipment1.dates.availableDate1)
              );
            }
          } else {
            // console.log("found date4", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentPickupDate) -
                new Date(shipment2.dates.appointmentPickupDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentPickupDate) -
                new Date(shipment1.dates.appointmentPickupDate)
              );
            }
          }
        }
      );
      this.setState({ shipments: resortedData });
    }

    if (sortType === "deliveryDate") {
      const resortedData = this.state.shipments.sort(
        (shipment1, shipment2, sortOrder) => {
          if (
            shipment1.dates.appointmentDeliveryDate === "" &&
            shipment2.dates.appointmentDeliveryDate !== ""
          ) {
            let shipment1Date1 = shipment1.dates.deliveryDate;
            // console.log("found date1", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1Date1) -
                new Date(shipment2.dates.appointmentDeliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentDeliveryDate) -
                new Date(shipment1Date1)
              );
            }
          }
          if (
            shipment2.dates.appointmentDeliveryDate === "" &&
            shipment1.dates.appointmentDeliveryDate !== ""
          ) {
            // console.log("found date2", this.state.currentSortOrder);
            let shipment2Date1 = shipment2.dates.deliveryDate;
            // console.log("found date2 using", shipment2Date1);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentDeliveryDate) -
                new Date(shipment2Date1)
              );
            } else {
              return (
                new Date(shipment2Date1) -
                new Date(shipment1.dates.appointmentDeliveryDate)
              );
            }
          }
          if (
            shipment2.dates.appointmentDeliveryDate === "" &&
            shipment1.dates.appointmentDeliveryDate === ""
          ) {
            // console.log("found date3", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.deliveryDate) -
                new Date(shipment2.dates.deliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.deliveryDate) -
                new Date(shipment1.dates.deliveryDate)
              );
            }
          } else {
            // console.log("found date4", this.state.currentSortOrder);
            if (this.state.currentSortOrder === "Ascending") {
              return (
                new Date(shipment1.dates.appointmentDeliveryDate) -
                new Date(shipment2.dates.appointmentDeliveryDate)
              );
            } else {
              return (
                new Date(shipment2.dates.appointmentDeliveryDate) -
                new Date(shipment1.dates.appointmentDeliveryDate)
              );
            }
          }
        }
      );
      this.setState({ shipments: resortedData });
    }
  }

  convertToStandardTime(time) {
    if (time === '') {
      return
    }
    const [hours, minutes] = time.split(":");
    const standardHours = (hours % 12 || 12).toString();
    const period = hours < 12 ? "AM" : "PM";
    return `${standardHours.padStart(2, "0")}:${minutes} ${period}`;
  }

  getCSVData = () => {
    // Map over results to create a new array of city and zip values
    const csvData = this.state.shipments.map((shipment) => {
      let packages = "";
      if (Array.isArray(shipment.packages)) {
        packages = shipment.packages
          .map(
            (unit) =>
              `${unit.quantity} ${unit.packaging.charAt(0).toUpperCase() + unit.packaging.slice(1)} | Dims: ${unit.dimensionLength} x ${unit.dimensionWidth} x ${unit.dimensionHeight}`
          )
          .join(", ");
      }

      return [
        shipment.carrierName,
        shipment.loadDetails.equipment,
        shipment.loadDetails.totalWeight,
        packages,
        shipment.shipper.address.city,
        shipment.shipper.address.zip,
        shipment.consignee.address.city,
        shipment.consignee.address.zip,
        shipment.loadDetails.miles,
        shipment.dates.appointmentPickupDate,
        this.convertToStandardTime(shipment.dates.pickupTimeIn),
        this.convertToStandardTime(shipment.dates.pickupTimeOut),
        shipment.dates.appointmentDeliveryDate,
        this.convertToStandardTime(shipment.dates.deliveryTimeIn),
        this.convertToStandardTime(shipment.dates.deliveryTimeOut),
        shipment.signedBy,
        shipment.charges.invoiceNumber,
        shipment.documents.map((doc) => doc.type),
        shipment.loadDetails.trailerNumber,
        shipment.loadDetails.truckNumber,
        shipment.chargesList.filter((charge) => charge.type === "estimatedCost").map((chg) => chg.total),
        shipment.charges.billedCost,
        shipment.charges.approvedCost,
        shipment.accessorialRequests.map((acc) => acc.accepted),
        shipment.accessorialRequests.map((acc) => acc.accessorialName),
      ];
    });

    // Add the header row to the beginning of the csvData array
    csvData.unshift([
      "Carrier Name",
      "Equipment",
      "Weight",
      "Handling Unit",
      "Origin City",
      "Origin Zip",
      "Destination City",
      "Destination Zip",
      "Status Miles",
      "Pickup Date",
      "Pickup Time In",
      "Pickup Time Out",
      "Delivery Date",
      "Delivery Time In",
      "Delivery Time Out",
      "Signed By",
      "Invoice #",
      "Documents Uploaded",
      "Trailer #",
      "Truck #",
      "Estimated Cost",
      "Billed Cost",
      "Approved Cost",
      "Accessorial Requests Accepted",
      "Accessorial Names",
    ]);

    return csvData;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shipments !== prevState.shipments) {
      const csvData = this.getCSVData();
      // Set the new csvData in the state
      this.setState({ csvData });
    }
    if (this.props.shipData.length === false) {
      this.setState({ results: [] });
    }
    if (this.props !== prevProps) {
      this.statupAndUpDateLogic();
    } else {
    }
  }

  // Show or Hide the section welcome mesage
  GenricCollectionMessageClose() {
    // console.log("GenricCollectionMessageClose fired");
    this.setState({ messageShow: "hide" });
    localStorage.setItem(`${this.props.id}message`, "hide");
  }

  // Change open status and plusminus icon
  openAndIconChange() {
    this.setState((prevState) => ({ open: !prevState.open }));
    if (this.state.plusMinus === "fas fa-plus") {
      this.setState({ plusMinus: "fas fa-minus" });
    } else {
      this.setState({ plusMinus: "fas fa-plus" });
    }
  }

  // We render cards only when the user clicks on a section
  delayedRenderClick() {
    this.setState((prevState) => ({
      delayedRenderState: !prevState.delayedRenderState,
    }));
  }

  // This just makes a hash for the React key="" for the checkboxes to use.   Some orders will appear multiple times and we need to differenetiate React key="" purely for the purpose of not duplicating React key=""
  hashForReactKey() {
    var idHash =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.setState({ reactKeyHash: idHash });
  }

  condrenderShipmentCard() {
    if (
      this.state.results &&
      this.state.delayedRenderState &&
      this.props.mode === "shipment"
    ) {
      return (
        <div>
          {this.state.shipments.map((item) => {
            return (
              <ShipmentCard2
                version={2}
                key={`${item.id}-${this.state.reactKeyHash}`}
                shipment={item}
                tableEditMode={this.props.tableEditMode}
                handleChangeTableItem={this.props.handleChangeTableItem}
                tableEditSelected={this.props.tableEditSelected}
                hilightFields={this.props.hilightFields}
                showFields={this.props.showFields}
                documents={this.props.documents}
                updateSingleShipment={this.props.updateSingleShipment}
                refreshShippments={this.props.refreshShippments}
              />
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  condrenderRateQuoteCard() {
    if (
      this.state.results &&
      this.state.delayedRenderState &&
      this.props.mode === "rateQuote"
    ) {
      return (
        <div id="rate-quote-container">
          {this.state.results.map((item) => {
            return (
              <RateRequestCard
                key={`${item.id}-${this.state.reactKeyHash}`}
                data={item}
                hilightFields={this.props.hilightFields}
                showFields={this.props.showFields}
              />
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { open } = this.state;
    return (
      <div className={this.state.enableDisable}>
        <span
          onClick={this.openAndIconChange}
          id={this.props.id}
          aria-controls="collapse-NeedsDocuments"
          aria-expanded={open}
          className="pointer"
        >
          <h3 onClick={this.delayedRenderClick}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flexStart",

                width: "100%",
              }}
            >
              <span style={{ width: "95%" }}>
                <i className={this.state.plusMinus} /> {this.props.description}{" "}
                ({this.state.totalRequests})
              </span>{" "}
              <span>
                <MailIcon
                  render={this.state.messagesIcon}
                  style={{ alignSelf: "end" }}
                  titleText="One or more shipments in this collection have an unread message."
                />
              </span>
            </div>
          </h3>
        </span>

        <Collapse in={this.state.open}>
          <div id="collapse-NeedsDocuments">
            <div className={this.state.messageShow}>
              <GenricCollectionMessage
                tipDescrption={this.props.tipDescrption}
                GenricCollectionMessageClose={this.GenricCollectionMessageClose}
              />
            </div>
            {this.props.mode !== 'rateQuote' && this.props.showFields.includes("CSVExport") && (
              <CSVLink data={this.state.csvData} filename={"Export.csv"}>
                Download CSV
              </CSVLink>
            )}
            {/* Sorting */}
            {this.props.type === "rates" ? null : (
              <span className="form-group d-flex justify-content-start">
                <select
                  className="form-control form-control-sm sortTypes"
                  as="select"
                  size="sm"
                  name="currentSortItem"
                  onChange={this.handleChangeSortType}
                >
                  <option defaultValue="BOL" value="BOL">
                    Sort By BOL
                  </option>
                  <option value="puApptDate">Pickup Date</option>
                  {/* <option value="puReqDate">Pick Up Requested Date</option> */}
                  <option value="deliveryDate">Delivery Date</option>
                </select>

                <span className="sortOrder">
                  <span
                    className="sortOrderClick"
                    onClick={this.changeSortOrder}
                  >
                    Sort Order: &nbsp;
                    {this.state.currentSortOrder === "Ascending" ? (
                      <span>
                        {" "}
                        Ascending <i className="fas fa-sort-up" />
                      </span>
                    ) : (
                      <span>
                        Descending <i className="fas fa-sort-down" />
                      </span>
                    )}
                  </span>
                </span>
              </span>
            )}

            {/* /Sorting */}

            {this.condrenderShipmentCard()}
            {this.condrenderRateQuoteCard()}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default GenericCollection;
