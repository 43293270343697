// ./Components/DocumentsModal.js

import React, { Component } from "react";
import Authentication from "../APIs/Authentication";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as moment from "moment";

class DocumentsModal extends Component {
  constructor() {
    super();
    this.state = {
      modalState: 'loaded',
      documents: [],
    };
  }

  componentDidMount() {
    Authentication.isLoggedIn();
    this.checkForNewDocuments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateWasSubmitted !== this.props.updateWasSubmitted) {
      this.checkForNewDocuments();
    }
  }

  checkForNewDocuments = () => {
    // set modalState to loading
    this.setState({ modalState: 'loading' })
    let webquotecontrol = this.props.id
    fetch("https://api.shippersedge.com/v4/shipment/" + webquotecontrol + "?include=all,gpsStatus,messages,logTypeNotice,documents&translate=v2", {
      "method": "GET",
      "headers": {
        "x-api-key": "vdhy8wwjUJrwhxFpfuECTiAW"
      }
    }).then(response => response.json()).then(data => {
      // console.log('DocumentsModal DocumentsModal', data)
      const resortedDocuments = data.documents.sort(
        (doc1, doc2) => doc1.id < doc2.id
      );

      this.setState({ documents: resortedDocuments }) // set modalState to loaded
      this.setState({ modalState: 'loaded' })
    })
      .catch(err => {
        console.error(err);
        this.setState({ modalState: 'error' })
      }
      );
  }



  render() {

    const now = Date.now();
    const today = moment(now).format("MM-DD-YYYY");
    return (
      <div>
        <Modal {...this.props} size="lg">
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Documents
            </Modal.Title>

            {this.state.modalState === 'loading' ? (
              <div class="spinner-grow" role="status" onClick={this.checkForNewDocuments}>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <svg
                onClick={this.checkForNewDocuments}
                title="Reload / Check For New Documents"
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
            )}
          </Modal.Header>

          <Modal.Body>

            {/* If moal state is loading */}
            {this.state.modalState === 'loading' ? (
              <>
                <div className="d-flex justify-content-center w-100  ">
                  <div className="d-flex flex-direction-row justify-content-center alert alert-primary border-primary w-50">
                    <div>Loading documents...</div>
                    <div class="spinner-border ml-2" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {/* If modal state is loaded */}

            {this.state.modalState === 'loaded' ? (
              <>
                <Row>
                  <div className="d-flex justify-content-center w-100  ">
                    <div className="alert alert-warning border border-warning" role="alert">
                      Not seeing the documents you just uploaded? <br />
                      Click the reload icon to check for new documents.
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <strong>Current Documents:</strong>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a
                      href={
                        "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=rateagreement&i=" +
                        this.props.id +
                        "&print&wsdl"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rate Agreement
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a
                      href={
                        "https://www2.shippersedge.com/includes/cfm/static_other.cfm?d=pickuprequest&i=" +
                        this.props.id +
                        "&print&wsdl"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pickup Request
                    </a>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <strong>Uploaded Documents:</strong> <br />
                    {this.state.documents.map((item, index) => {

                      if (item.name === "null") {
                        return null
                      } else {
                        return (
                          <span id={item.id} key={index}>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.type} -{" "}
                            </a>{" "}
                            Uploaded at:{" "}
                            {moment(item.createdAt).format("MM-DD-YYYY, HH:MM:SS")}{" "}
                            {today === moment(item.createdAt).format("MM-DD-YYYY") ? (
                              <span style={{ backgroundColor: "#f9e79f" }}>
                                (Uploaded Today)
                              </span>
                            ) : null}
                            <br />
                          </span>
                        );
                      }
                    })}
                  </Col>
                </Row>
              </>
            ) : null}


            {/* if modal state is error */}

            {this.state.modalState === 'error' ? (

              <>
                <div>There was an error loading documents, please try clicking the reload icon.</div>

              </>
            ) : null}

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              className="allBtn"
              onClick={this.props.onHideDocs}
            >
              Close
            </Button>
          </Modal.Footer>

        </Modal>
      </div>
    );
  }
}

export default DocumentsModal;
