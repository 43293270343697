import React, { useState } from 'react'
import * as moment from "moment";

const AccessorialRequest = ({ userData, addAccessorialRequest, newAccesorialRequests, clearAddedAccessorials, shipment, showOrHide }) => {

    const [accessoralValue, setAccessoralValue] = useState(0)
    const [accessoralID, setAccessoralID] = useState(0)
    const [readOnlyAmountAndRequest, setReadOnlyAmountAndRequest] = useState(true)

    let userId = userData.id

    let checkSelectChange = (event) => {
        // console.log('checkSelectChange', event.target.value)
        if (event && (event.target.value !== "")) {
            setReadOnlyAmountAndRequest(false)
            setAccessoralID(event.target.value)
        }
    }

    let changeaccessoralValue = (event) => {
        if (event && (event.target.value !== "")) {
            setAccessoralValue(event.target.value)
        }
    }

    let submitNewAccessoralReqeustToArray = () => {
        let accessorialFormDisplay = userData.clientAccessorials.filter((accesorial) => {
            if (parseInt(accesorial.accessorialID) === parseInt(accessoralID)) {
                return accesorial.accessorialFormDisplay
            } else {
                console.warn("AccessorialRequest, submitNewAccessoralReqeustToArray could not identify the accessorial selected to pick a name.")
                return null
            }
        })
        addAccessorialRequest({
            accessorialId: accessoralID,
            amount: accessoralValue,
            carrier_userId: localStorage.getItem("id"),
            formDisplayName: accessorialFormDisplay[0].accessorialFormDisplay,
            webquotecontrol: shipment.id

        })
    }



    if (userData.clientAccessorials && (showOrHide === "show" || showOrHide === "hilight")) {

        return (
            <fieldset id="accesorialRequests" className="border p-2 d-inline-block">
                <legend className="w-auto">Accessorial Requests</legend>
                <div className="mt-3 mb-3 d-flex flex-row">
                    {/* Request */}
                    <div className="">
                        {!moment().diff(moment(shipment.dates.pickupDate), 'days') <= 7 ? (<><div >
                            <label>Request Additional Accessorial Charge(s):</label>
                        </div>
                            <div className="input-group">
                                <select className="mr-2 select form-control-sm" id="accessorialCharge" onChange={(event) => checkSelectChange(event)}>
                                    <option selected value="">Choose...</option>

                                    {userData.clientAccessorials.map((accesorial, index) => {
                                        return (<option key={index} value={accesorial.accessorialID}>{accesorial.accessorialFormDisplay}</option>)
                                    })}
                                </select>
                                <input type="number" className="mr-2 form-control form-control-sm" placeholder="Accessorial Price" readOnly={readOnlyAmountAndRequest} onChange={(event) => changeaccessoralValue(event)}></input>
                                <button className="mr-2 btn btn-outline-secondary btn-sm" type="button" id="accessorialChargeAdd" disabled={readOnlyAmountAndRequest} onClick={() => submitNewAccessoralReqeustToArray()}>Add Request</button>

                            </div></>) : null}

                        {shipment.accessorialRequests && shipment.accessorialRequests.length ?
                            (

                                <React.Fragment>
                                    <div className=" mt-3 card">
                                        <div className="card-body">
                                            <label>Past Accessorial Requests:</label>
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>



                                                    {shipment.accessorialRequests.map((request, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{request.accessorialFormDisplay}</td>
                                                                <td>${request.amount}</td>
                                                                <td>
                                                                    {request.accepted === 1 ? "Accepted" : null}
                                                                    {request.declined === 1 ? "Declined" : null}
                                                                    {request.declined === 0 && request.accepted === 0 ? "Pending" : null}
                                                                </td>
                                                                <td>{request.declineReason}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            : null
                        }
                    </div>
                    {/* Selected Accessorial Requests  */}
                    {newAccesorialRequests.length ?
                        (
                            <div className="mt-3 ml-4" >

                                <label>Selected Accessorial Requests:</label>
                                <div className="card">

                                    <div className="card-body">
                                        <ul>
                                            {newAccesorialRequests.map((request, index) => {
                                                return (
                                                    <React.Fragment key={index}><li>{request.formDisplayName}, ${request.amount}</li></React.Fragment>
                                                )
                                            })}
                                        </ul>

                                    </div>
                                    <div className="card-footer bg-transparent">
                                        <button className="btn btn-outline-secondary btn-sm" onClick={() => clearAddedAccessorials()}>Reset</button>
                                    </div>
                                </div>

                                <div className="alert alert-warning d-inline-block mt-2">Click "Update Shipment" to send request(s).</div>
                            </div>
                        )
                        : null}

                </div >
            </fieldset>
        )
    } else {
        return null
    }
}

export default AccessorialRequest
