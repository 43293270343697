import React, { useContext, useState, useEffect } from "react";
import UserContext from "../../../UserDataContext";
import CheckProNum from "../../../APIs/CheckProNum";
import CheckBilledVsEstCost from "../../../CustLogicAndFilters/CheckBilledVsEstCost";
import CheckBilledCostReqPro from "../../../CustLogicAndFilters/CheckBilledCostReqPro";
import CheckAndSubmitChargesNotes from "../../../CustLogicAndFilters/CheckAndSubmitChargesNotes";
import UploadFile from "../../../APIs/UploadFile";
import SubmitAccessorialRequests from '../../../APIs/SubmitAccessorialRequests'
import UpdateShipmentStatus from "../../../APIs/UpdateShipmentStatus";
import CheckInvoiceNum from "../../../APIs/CheckInvoiceNum";
import { basicDateTimeValidation } from "../../../APIs/Helpers";

const SubmitUpdate = props => {
  const [status, setStatus] = useState(" ");
  const [alertType, useAlertType] = useState("alert alert-light border-light");
  const [disableUpdates, seteDisableUpdates] = useState(false);
  const userData = useContext(UserContext);
  const carrierId = localStorage.getItem("carrierId");

  // console.log('userData', userData);

  useEffect(() => {

    if (props?.shipment?.settlementId) {
      seteDisableUpdates(true);
      StatusSetter("Shipment has been settled, no updates allowed.", "alert alert-secondary border-secondary");
    }

  }, [])


  function AlertBox() {
    return (
      <div className={`d-flex flex-column justify-content-center h-100 ${alertType}`} role="alert">
        {status}
      </div>
    );
  }

  function StatusSetter(text, type) {
    setStatus(text);
    useAlertType(type);
  }

  function ProNumberCheckTimedOut() {
    StatusSetter(
      "Pro Number check retried three times and then failed due to a timeout. Please try again.",
      "alert alert-danger border-danger"
    );
    props.setAndShowGenericModal(
      "Pro Number Check Timeout",
      "Pro Number check retried three times and then failed due to a timeout. Please try again."
    );
  }


  function InvoiceNumberCheckTimedOut() {
    StatusSetter(
      "Invoice Number check retried three times and then failed due to a timeout. Please try again.",
      "alert alert-danger border-danger"
    );
    props.setAndShowGenericModal(
      "Invoice Number Check Timeout",
      "Invoice Number check retried three times and then failed due to a timeout. Please try again."
    );
  }

  function ProNumberCheckDuplicateFound() {
    props.setAndShowGenericModal(
      "Duplicate Invoice / Pro Number",
      "The Invoice / Pro Number you entered has already been used for another shipment.  Try searching All Reference Numbers to find the shipment that is using the Invoice / Pro Number you entered.  The shipment has not been updated."
    );
    StatusSetter("This shipment has not been updated due to having a duplicate pro number. Please update the Pro Number.", "alert alert-danger");
  }

  //
  // This is where the update starts
  //

  const SequentialStart = async () => {
    // console.log('SequentialStart', userData)

    StatusSetter("Verifying..", "alert alert-secondary border-secondary");

    //
    // Check Pro Number
    //
    if (props.shipment.charges.trackingNumber && userData.clientCode !== "kissner" && (props.shipment.charges.trackingNumber !== "Enter Pro Number" && props.shipment.charges.trackingNumber !== "" && props.shipment.charges.trackingNumber !== null)) {
      StatusSetter("Checking Pro Number", "alert alert-secondary border-secondary");
      const checkProNum1 = await CheckProNum.CheckProNumv2(
        props.shipment.id,
        carrierId,
        props.shipment.charges.trackingNumber,
        5000
      );
      if (checkProNum1 === true || checkProNum1 === "Aborted") {
        if (checkProNum1 === true) {
          ProNumberCheckDuplicateFound();
        }
        if (checkProNum1 === "Aborted") {
          StatusSetter("Checking Pro Number (retry).", "alert alert-secondary border-secondary");
          const checkProNum2 = await CheckProNum.CheckProNum(
            props.shipment.id,
            carrierId,
            props.shipment.charges.trackingNumber,
            10000
          );
          if (checkProNum2 === true) {
            ProNumberCheckDuplicateFound();
          }
          if (checkProNum2 === "Aborted") {
            StatusSetter(
              "Checking Pro Number (retry again).",
              "alert alert-secondary border-secondary"
            );
            const checkProNum3 = await CheckProNum.CheckProNum(
              props.shipment.id,
              carrierId,
              props.shipment.charges.trackingNumber,
              20000
            );
            if (checkProNum3 === true) {
              ProNumberCheckDuplicateFound();
            }
            if (checkProNum3 === "Aborted") {
              StatusSetter(
                "Checking Pro Number failed on 3 tries.",
                "alert alert-secondary border-secondary"
              );
              ProNumberCheckTimedOut();
            }
          }
        }
        return false;
      }
    }
    //
    // Check Invoice Number
    //
    if (props.shipment.charges.invoiceNumber && userData.clientCode !== "kissner" && (props.shipment.charges.invoiceNumber !== "Enter Pro Number" && props.shipment.charges.invoiceNumber !== "" && props.shipment.charges.invoiceNumber !== null)) {
      StatusSetter("Checking Invoice Number", "alert alert-secondary border-secondary");
      const checkInvoiceNum1 = await CheckInvoiceNum.CheckInvoiceNum(
        props.shipment.id,
        carrierId,
        props.shipment.charges.invoiceNumber,
        5000
      );
      if (checkInvoiceNum1 === true || checkInvoiceNum1 === "Aborted") {
        if (checkInvoiceNum1 === true) {
          ProNumberCheckDuplicateFound();
        }
        if (checkInvoiceNum1 === "Aborted") {
          StatusSetter("Checking Invoice Number (retry).", "alert alert-secondary border-secondary");
          const checkProNum2 = await CheckInvoiceNum.CheckInvoiceNum(
            props.shipment.id,
            carrierId,
            props.shipment.charges.invoiceNumber,
            10000
          );
          if (checkProNum2 === true) {
            ProNumberCheckDuplicateFound();
          }
          if (checkProNum2 === "Aborted") {
            StatusSetter(
              "Checking Invoice Number (retry again).",
              "alert alert-secondary border-secondary"
            );
            const checkProNum3 = await CheckInvoiceNum.CheckInvoiceNum(
              props.shipment.id,
              carrierId,
              props.shipment.charges.invoiceNumber,
              20000
            );
            if (checkProNum3 === true) {
              ProNumberCheckDuplicateFound();
            }
            if (checkProNum3 === "Aborted") {
              StatusSetter(
                "Checking Invoice Number failed on 3 tries.",
                "alert alert-secondary border-secondary"
              );
              InvoiceNumberCheckTimedOut();
            }
          }
        }
        return false;
      }
    }



    //
    // Check Billed Vs Estimated cost
    //
    if (userData.clientCode !== "kissner" && userData.clientCode !== "foremost" && userData.clientCode !== "kwiktrip") {
      // console.log('------------')
      // console.log('BILLEDVSEST')
      // console.log('-----------')
      StatusSetter("Checking Billed vs Estimated Cost", "alert alert-secondary border-secondary");

      let estCost

      if (props.shipment.chargesList && props.shipment.chargesList.length) {
        let checkForEstCostCharg = props.shipment.chargesList.filter((charge) => charge.type === "estimatedCost")
        if (checkForEstCostCharg.length) { estCost = checkForEstCostCharg[0].total }

      }

      const billedVsEstimatedCost = await CheckBilledVsEstCost.BilledvsEstCost(
        props.shipment.charges.billedCost,
        estCost,
        props.chargesMessage
      );
      // console.log('billedVsEstimatedCost', billedVsEstimatedCost)
      if (billedVsEstimatedCost !== true && ((userData.clientCode !== "pickseed" && props.shipment.billto.type === "COLLECT") && userData.clientCode !== "bailey" && !userData.clientCode.includes("halliburton"))) {
        if (billedVsEstimatedCost === "enterChargesMessage") {
          // console.log('billedVsEstimatedCost MODAL TIME')
          props.setAndShowGenericModal(
            "Billed Cost Vs Estimated Cost",
            "If the Billed Cost is different than the Estimated Cost you must enter a note in the Charges Notes field, and submit the update again.  Thank you."
          );
          return false;
        }
      }
    }

    // If a settlement id exists, do not allow update (shipment is settled)
    if (props.shipment?.billing?.settlmentId && props.shipment?.billing?.settlmentId !== null) {
      StatusSetter("If a shipment is settled you can not submit and update.", "alert alert-danger border-danger");
      return false;
    }

    //
    // Gould Pro or Tracking number required if there is a billed cost entered
    //
    StatusSetter("Checking Invoice and Pro", "alert alert-secondary border-secondary");
    const gouldBilledCostRequiresProOrTracking = await CheckBilledCostReqPro.CheckBilledCostReqPro(
      carrierId,
      props.shipment.charges.billedCost,
      props.shipment.charges.trackingNumber
    );
    if (gouldBilledCostRequiresProOrTracking === "needTrackingNumber") {
      props.setAndShowGenericModal(
        "An Invoice / Pro Number Is Required When Entering Billed Charges",
        "Please update the Invoice / Pro Number when updating the Billed Charges."
      );
      return false;
    }

    //
    // Check and Submit Charges Notes
    //
    StatusSetter("Checking / Submitting Notes", "alert alert-secondary border-secondary");
    await CheckAndSubmitChargesNotes.CheckAndSubmitChargesNotes2(
      props.shipment.log,
      props.chargesMessage,
      props.shipment.id
    );
    // console.log('checkSubmitChargesNotes', checkSubmitChargesNotes);
    //
    // Build Form Data for Shpment Information Update
    //
    StatusSetter("Preparing Update", "alert alert-secondary border-secondary");
    const buildFormData = await props.buildFormDataForShipmentUpdate();
    const formData = buildFormData;


    //
    // Validate Dates (basic logic validation to determine if a date makes sense such as pickup date comes before delivery date)
    //
    let dateTimeValidation = basicDateTimeValidation(formData, props.shipment, props.puTimeIn, props.puOutTime, props.delInTime, props.delOutTime)
    if (!dateTimeValidation.validation) {
      StatusSetter("Update failed. " + dateTimeValidation.reason, "alert alert-danger border-danger")
      return false
    }

    console.log('props.newAccesorialRequests', props.newAccesorialRequests)

    //     
    //   Submit accesoral requests 
    //

    StatusSetter("Checking for accesorial requests", "alert alert-secondary border-secondary");
    let sumbitAccessorialResult = await SubmitAccessorialRequests(props.newAccesorialRequests)
    if (sumbitAccessorialResult) {

      props.clearAddedAccessorials()

    } else {
      StatusSetter(
        "Submitting requested accessorials failed due to network timeout, please refresh your browser and try again.",
        "alert alert-danger border-danger"
      );
    }

    //
    // Kissner, no status change to DEL allowed unless current status is PU-OUT
    //
    if (userData && userData.clientCode) {
      if (userData.clientCode === "kissner") {
        // console.log('test0901')
        // console.log('test0901 customer', userData.clientCode)
        // console.log('test0901 existing status', props.propsShipment.status.statusId)
        let previousStatus = props.propsShipment.status.statusId
        let formDataStatus = "";
        for (var pair of formData.entries()) {
          if (pair[0] === 'status') {
            formDataStatus = pair[1];
          }
        }
        // console.log('test0901 formData status', formDataStatus)
        if (previousStatus !== "PU-Out") {
          // console.log('test0901 violation')

          if (props.newAccesorialRequests.length) {
            props.setAndShowGenericModal(
              "Accessorial Request Submitted",
              "Accessorial Request Submitted.  However, other shipment status changes may only be updated if the current status is 'PU-Out'."
            );
            StatusSetter("Shipment Updated!", "alert alert-success border-success");
          } else {
            props.setAndShowGenericModal(
              "Status Change Failed",
              "A shipment may only be updated if the current status is 'PU-Out'."
            );
            StatusSetter("Shipment not updated.", "alert alert-secondary border-secondary");
          }
          return false;
        } else {
          // console.log('test0901 ALL GOOD')
        }
      }
    }

    //
    // KwikTrip no change to del without actual del dates and times
    //
    if (userData && userData.clientCode) {
      if (userData.clientCode === "kwiktrip") {
        let previousStatus = props.propsShipment.status.statusId
        let formDataStatus = ""
        for (var pair of formData.entries()) {
          if (pair[0] === 'status') {
            formDataStatus = pair[1]
          }
        }
        //    deldate
        let previousDelDate = props.propsShipment.dates.deliveryDate
        let formDelDate = ""
        for (var pair of formData.entries()) {
          if (pair[0] === 'status') {
            formDelDate = pair[1]
          }
        }
        // delInTime
        let previousDelInTime = props.propsShipment.dates.deliveryTimeIn
        let formDelInTime = ""
        for (var pair of formData.entries()) {
          if (pair[0] === 'delInTime') {
            formDelInTime = pair[1]
          }
        }
        // delOutTime
        let previousDelOutTime = props.propsShipment.dates.deliveryTimeOut
        let formDelOutTime = ""
        for (var pair of formData.entries()) {
          if (pair[0] === 'delOutTime') {
            formDelOutTime = pair[1]
          }
        }

        if (previousStatus !== "DEL" && formDataStatus === "DEL") {
          if ((userData.clientCode !== "kwiktrip") && ((previousDelDate === "" || previousDelInTime === "" || previousDelOutTime === "") && (formDelDate === "" || formDelInTime === "" || formDelOutTime === ""))) {
            props.setAndShowGenericModal(
              "Status Change Failed",
              "A shipment may only be marked delivered if the actual delivery date and times are set."
            );
            StatusSetter("Shipment not updated.", "alert alert-secondary border-secondary");
            return false;
          } else if (userData.clientCode === "kwiktrip" && (formDelDate === "")) {
            props.setAndShowGenericModal(
              "Status Change Failed",
              "A shipment may only be marked delivered if the actual delivery date are set."
            );
            StatusSetter("Shipment not updated.", "alert alert-secondary border-secondary");
            return false;
          } else {
            // 
          }
        } else {
          // 
        }
      }
    }

    //
    //  Schuff, no submit updates if "settled" (CLOSED status)
    //

    if (userData && userData.clientCode) {
      if (userData.clientCode === "Schuff" || userData.clientCode === "graywolf") {
        let status = "";
        for (var pair of formData.entries()) {
          if (pair[0] === 'status') {
            status = pair[1];
          }
        }
        if (status === "CLOSED") {
          StatusSetter("If a shipment is in CLOSED status you can not submit and update.", "alert alert-danger border-danger");
          return false;
        }
      }
    }
    // Foremost require pu date and times and del date and times if status is "DEL"
    if (userData && userData.clientCode) {

      if (userData.clientCode === "foremost") {
        let status = "";
        for (var pair of formData.entries()) {
          if (pair[0] === 'status') {
            status = pair[1];
          }
        }
        if (status === "DEL") {
          let pudate = "";
          let puInTime = "";
          let puOutTime = "";
          let deldate = "";
          let delInTime = "";
          let delOutTime = "";
          for (var pair of formData.entries()) {
            if (pair[0] === 'pudate') {
              pudate = pair[1];
            }
            if (pair[0] === 'puInTime') {
              puInTime = pair[1];
            }
            if (pair[0] === 'puOutTime') {
              puOutTime = pair[1];
            }
            if (pair[0] === 'deldate') {
              deldate = pair[1];
            }
            if (pair[0] === 'delInTime') {
              delInTime = pair[1];
            }
            if (pair[0] === 'delOutTime') {
              delOutTime = pair[1];
            }
          }

          // console.log('pudate', pudate)
          // console.log('puInTime', puInTime)
          // console.log('puOutTime', puOutTime)
          // console.log('deldate', deldate)
          // console.log('delInTime', delInTime)
          // console.log('delOutTime', delOutTime)

          if ((props.shipment.dates.pickupDate === "" || props.shipment.dates.pickupTimeIn === "" || props.shipment.dates.pickupTimeOut === "" || props.shipment.dates.deliveryDate === "" || props.shipment.dates.deliveryTimeIn === "" || props.shipment.dates.deliveryTimeOut === "") && (pudate === '' || puInTime === '' || puOutTime === '' || deldate === '' || delInTime === '' || delOutTime === '')) {
            StatusSetter("Update failed.  If a shipment is marked Delivered the Actual Pickup / Delivery dates and times must also be set.", "alert alert-danger border-danger");
            return false;
          }



        }
      }
    }


    //
    // Submit Update to Shpment Information (all fields but document upload related fields) API
    //
    StatusSetter("Submitting Update", "alert alert-secondary border-secondary");
    const updateShipment = await UpdateShipmentStatus.Update(formData, 5000);
    // console.log("submitupdate check", updateShipment);
    if (updateShipment === "Aborted") {
      // Second try if the first fails
      StatusSetter("Update failed, trying again...", "alert alert-secondary border-secondary");
      const updateShipmentTryAgain = await UpdateShipmentStatus.Update(
        formData,
        10000
      );
      if (updateShipmentTryAgain === "Aborted") {
        //   Third Try if the frst and second fail
        StatusSetter(
          "Update failed, trying again (second time)...",
          "alert alert-secondary border-secondary"
        );
        const updateShipmentTryAgain2 = await UpdateShipmentStatus.Update(
          formData,
          15000
        );
        if (updateShipmentTryAgain2 === "Aborted") {
          StatusSetter(
            "Updates failed to update due to network timeout, please refresh your browser and try again.",
            "alert alert-danger border-danger"
          );
        } else if (updateShipment === "Success") {
          // StatusSetter("Shipment Updated!!!", "alert alert-success");
        }
      } else if (updateShipment === "Success") {
        // StatusSetter("Shipment Updated!!", "alert alert-success");
      }
    } else if (updateShipment === "Success") {
      // StatusSetter("Shipment Updated!", "alert alert-success");
    } else {
      StatusSetter("Failed to update, please try again.", "alert alert-danger border-danger");
    }

    //
    // Document Upload API Call
    // The returns from the API call look a little weird, need to look into that later.
    //
    StatusSetter("Checking for Document Upload", "alert alert-secondary border-secondary");
    const CheckDocument = () => {
      // console.log('props for doc check', props.documentUploadFile)
      return new Promise(resolve => {
        // console.log("CheckDocument");
        if (props.documentUploadFile === null) {
          // console.log("Submit Update found NO document");
          resolve(false);
        } else {
          // console.log("Submit Update found A document");
          resolve(true);
        }
      });
    };

    const documentCheck = await CheckDocument();

    if (documentCheck === false) {
      // do nothing


    } else if (props.documentUploadFileType === null || props.documentUploadFileType === undefined) {
      StatusSetter("Please select a document type from the dropdown menu when submitting documents.", "alert alert-warning border-warning");
      alert("Please select a document type from the dropdown menu when submitting documents.")
      return false
    } else {

      StatusSetter("Preparing Document Upload", "alert alert-secondary border-secondary");
      const buildDocumentUploadFormData = await props.documentUploadBuildFormData();
      // console.log(
      //   "buildDocumentUploadFormData in Sumbit Update",
      //   buildDocumentUploadFormData
      // );
      StatusSetter("Uploading Document...", "alert alert-secondary  border-secondary");
      const uploadDocument1 = await UploadFile.UploadNew(
        buildDocumentUploadFormData,
        10000
      );
      if (uploadDocument1 === "Aborted") {
        StatusSetter(
          "Document upload timed out, trying Again ...",
          "alert alert-secondary border-secondary"
        );
        const uploadDocument2 = await UploadFile.UploadNew(
          buildDocumentUploadFormData,
          20000
        );
        if (uploadDocument2 === "Aborted") {
          StatusSetter(
            "Document upload timed out, trying again (second retry) ...",
            "alert alert-secondary border-secondary"
          );
          const uploadDocument3 = await UploadFile.UploadNew(
            buildDocumentUploadFormData,
            35000
          );
          if (uploadDocument3 === "Aborted") {
            StatusSetter(
              "Document failed to upload due to network timeout, please refresh your browser and try again.",
              "alert alert-danger border-danger"
            );
            return false;
          }
        }
      }
    }


    StatusSetter("Shipment Updated!", "alert alert-success border-success");
    props.updateWasSubmittedIncrement()
    if (props.refreshShippments) {
      props.refreshShippments();
    }

    // props.localUpDateSingleShipment();
    return true;
  };

  return (
    <div
      className="d-flex flex-row"
      style={{ height: "80px", marginBottom: "5px" }}
    >
      <div
        className="justify-content-center align-self-center w-50"
        style={{ marginRight: "10px" }}
      >
        {disableUpdates
          ?
          <button
            type="button"
            className="btn btn-lg  btn-success"
            onClick={SequentialStart}
            disabled

          >          Update Shipment
          </button>
          :
          <button
            type="button"
            className="btn btn-lg  btn-success"
            onClick={SequentialStart}


          >          Update Shipment
          </button>
        }



      </div>

      <div className="flex-shrink-1 d-flex flex-column justify-content-center ">
        <AlertBox />
      </div>
    </div>
  );
};

export default SubmitUpdate;
